/* eslint-disable max-len */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable @typescript-eslint/no-empty-function */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useContext, useState } from 'react';
import Image from 'react-bootstrap/Image';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DeviceModelStatus } from '@gpt/commons';
import ForceDeviceTrueIcon from '../../../../assets/icons/force-device-true-icon.svg';
import ForceDeviceFalseIcon from '../../../../assets/icons/force-device-false-icon.svg';
import { sPortMinMCRWriteAction } from '../../../../store/deviceInstances/middleware/deviceMethod';
import { ForceDialogControl } from '../../ForceDialogControl/ForceDialogControl';
import { ForceDialogPasswordContext, ForceDialogSessionContext } from '../../Context/ForceDialogContext';
import './ForceDeviceEnableControl.scss';

const actionIdForceOn = 's-port--mini-mcr--force-enabled-on';
const actionIdForceOff = 's-port--mini-mcr--force-enabled-off';

export interface ForceDeviceEnableControlProps {
  deviceInstanceId: string;
  switchValue: boolean;
  actionOn: DeviceModelStatus.UI.SPort.ForceDeviceEnableAction;
  actionOff: DeviceModelStatus.UI.SPort.ForceDeviceEnableAction;
}

const ForceDeviceEnableControl: React.FC<ForceDeviceEnableControlProps> = (
  props: ForceDeviceEnableControlProps,
): React.ReactElement => {
  const {
    deviceInstanceId, switchValue, actionOn, actionOff,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { resetSession } = useContext(ForceDialogSessionContext);
  const { password } = useContext(ForceDialogPasswordContext);

  const [showDialog, setShowDialog] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const image = switchValue ? <Image src={ForceDeviceTrueIcon} className="forcedeviceenable-icon" />
    : <Image src={ForceDeviceFalseIcon} className="forcedeviceenable-icon" />;

  const action = !switchValue ? actionOn : actionOff;
  const actionId = !switchValue ? actionIdForceOn : actionIdForceOff;
  const title = !switchValue ? t<string>('FORCE_DEVICE_ON__TITLE') : t<string>('FORCE_DEVICE_OFF__TITLE');
  const message = !switchValue ? t<string>('FORCE_DEVICE_ON__MESSAGE') : t<string>('FORCE_DEVICE_OFF__MESSAGE');

  return (
    <div>
      <div className={`forcedeviceenable-container forcedeviceenable-color--${switchValue}`}>
        <div className="forcedeviceenable-text">
          {t('FORCE_DEVICE')}
        </div>
        <div
          role="button"
          className={enabled ? '' : 'forcedeviceenable-button_disabled'}
          tabIndex={0}
          onClick={() => {
            setEnabled(false);
            setShowDialog(true);
          }}
          onKeyUp={() => {}}
        >
          {image}
        </div>
      </div>
      <ForceDialogControl
        title={title}
        message={message}
        actionId={actionId}
        showDialog={showDialog}
        deviceInstanceId={deviceInstanceId}
        onAccept={() => {
          setEnabled(false);
          resetSession();
          dispatch(sPortMinMCRWriteAction({
            actionId,
            targetInstance: deviceInstanceId,
            memoryAddress: action.address,
            ui16value: action.value,
            password,
          }));
          setShowDialog(false);
        }}
        onCancel={() => {
          setEnabled(true);
          setShowDialog(false);
        }}
        onFailed={() => {
          setEnabled(true);
        }}
        onSuccess={() => {
          setEnabled(true);
        }}
      />
    </div>
  );
};

export default ForceDeviceEnableControl;
