/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import './ForceAnalogOutputControl.scss';
import { useTranslation } from 'react-i18next';
import { DeviceModelStatus } from '@gpt/commons';
import { ForceAnalogAbsoluteOutput } from '../ForceAnalogAbsoluteOutput/ForceAnalogAbsoluteOutput';
import { ForceAnalogPercentOutput } from '../ForceAnalogPercentOutput/ForceAnalogPercentOutput';

export interface ForceAnalogOutputControlProps {
  title: string;
  text: string;
  statusDescriptor: DeviceModelStatus.StatusDescriptor;
  statusValue: DeviceModelStatus.StatusValue;
  rangeStartValue: number;
  rangeEndValue: number;
  button: DeviceModelStatus.UI.SPort.ForceAnalogOutputButton;
  type: string;
  deviceInstanceId: string;
  disabled: boolean;
}

export const ForceAnalogOutputControl: React.FC<ForceAnalogOutputControlProps> = (
  props: ForceAnalogOutputControlProps,
): React.ReactElement => {
  const {
    title, text, statusDescriptor, statusValue, button, type,
    rangeStartValue, rangeEndValue, deviceInstanceId, disabled,
  } = props;
  const { t } = useTranslation();
  const statusDescUnit = statusDescriptor.unit === undefined ? undefined : t<string>(statusDescriptor.unit);

  const analogOutput = type === DeviceModelStatus.UI.SPort.ForceAnalogOutputType.ABSOLUTE ? (
    <ForceAnalogAbsoluteOutput
      button={button}
      deviceInstanceId={deviceInstanceId}
      statusDescriptor={statusDescriptor}
      statusValue={statusValue}
      rangeStartValue={rangeStartValue}
      rangeEndValue={rangeEndValue}
      disabled={disabled}
      unit={statusDescUnit}
    />
  ) : (
    <ForceAnalogPercentOutput
      button={button}
      deviceInstanceId={deviceInstanceId}
      statusDescriptor={statusDescriptor}
      statusValue={statusValue}
      rangeStartValue={rangeStartValue}
      rangeEndValue={rangeEndValue}
      disabled={disabled}
      unit={statusDescUnit}
    />
  );

  return (
    <div className="forceanalogoutput-container">
      <div className="forceanalogoutput-title">{t(title)}</div>
      <div className="forceanalogoutput-text">{t(text)}</div>
      <div className="forceanalogoutput-output">
        {analogOutput}
      </div>
    </div>
  );
};
