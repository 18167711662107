/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { Action } from 'redux';

export const SET_CONTEXT_MENU_HELP = 'SET_CONTEXT_MENU_HELP';
export const SET_CONTEXT_PARAM_HELP = 'SET_CONTEXT_PARAM_HELP';
export const SET_CONTEXT_PARAM_WARNING = 'SET_CONTEXT_PARAM_WARNING';
export const CLEAN_CONTEXT_PARAM = 'CLEAN_CONTEXT_PARAM';

export interface ContextHelpType {
  title: string;
  text: string;
  message?: string;
}

export interface ContextHelpState {
  menu: ContextHelpType;
  parameter: ContextHelpType;
  warning?: string;
}

interface SetContextMenuHelp extends Action<typeof SET_CONTEXT_MENU_HELP> {
  payload: ContextHelpType;
}

interface SetContextParamHelp extends Action<typeof SET_CONTEXT_PARAM_HELP> {
  payload: ContextHelpType;
}

interface SetContextParamWarning extends Action<typeof SET_CONTEXT_PARAM_WARNING> {
  payload?: string;
}

type CleanContextParameter = Action<typeof CLEAN_CONTEXT_PARAM>

export type ContextHelpActionTypes = SetContextMenuHelp
 | SetContextParamHelp
 | SetContextParamWarning
 | CleanContextParameter;
