/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { ChannelColorType } from '../CaparocHelper';
import './CaparocChannel.css';

export interface CaparocChannelProps {
  channelNumber: number;
  nomCurVal: number;
  outCurVal: number;
  color: ChannelColorType,
  inactiveChannel?: boolean,
}

// eslint-disable-next-line max-len
export const CaparocChannel: React.FC<CaparocChannelProps> = (props: CaparocChannelProps):
  React.ReactElement => {
  const {
    channelNumber, nomCurVal, outCurVal, color, inactiveChannel,
  } = props;
  const { t } = useTranslation();

  const channelColor = `caparoc-channel__color-${color === 'red' ? 'red-blink' : color}`;

  return (
    <div className="caparoc-channel__container">
      <div className={`caparoc-channel__title ${inactiveChannel ? 'caparoc-channel__text-inactive' : ''}`}>{`${t('CHANNEL')} ${channelNumber}`}</div>
      <div className={`caparoc-channel__led-off ${inactiveChannel ? 'caparoc-channel__led-inactive' : `${channelColor}`}`} />
      <div className={`caparoc-channel__nominal-current-label ${inactiveChannel ? 'caparoc-channel__hidden' : ''}`}>{t('NOMINAL_CURRENT')}</div>
      <div className={`caparoc-channel__nominal-current-value ${inactiveChannel ? 'caparoc-channel__hidden' : ''}`}>{`I nom.: ${nomCurVal} A`}</div>
      <div className={`caparoc-channel__output-current-label ${inactiveChannel ? 'caparoc-channel__hidden' : ''}`}>{t('OUTPUT_CURRENT')}</div>
      <div className={`caparoc-channel__output-current__container ${inactiveChannel ? 'caparoc-channel__hidden' : ''}`}>
        <div className="caparoc-channel__output-current__container-label">I ist:</div>
        <div className="caparoc-channel__output-current__container-value">{`${outCurVal} A`}</div>
      </div>
    </div>
  );
};
