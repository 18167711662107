/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import {
  ContextHelpActionTypes,
  ContextHelpType,
  SET_CONTEXT_MENU_HELP,
  SET_CONTEXT_PARAM_HELP,
  SET_CONTEXT_PARAM_WARNING,
} from './types';

const setContextMenuHelp = (payload: ContextHelpType): ContextHelpActionTypes => ({
  type: SET_CONTEXT_MENU_HELP,
  payload,
});

const setContextParameterHelp = (payload: ContextHelpType): ContextHelpActionTypes => ({
  type: SET_CONTEXT_PARAM_HELP,
  payload,
});

const setContextParameterWarning = (payload: string | undefined): ContextHelpActionTypes => ({
  type: SET_CONTEXT_PARAM_WARNING,
  payload,
});

const cleanContextParameter = (): ContextHelpActionTypes => ({
  type: SET_CONTEXT_PARAM_WARNING,
});

export {
  setContextMenuHelp,
  setContextParameterHelp,
  setContextParameterWarning,
  cleanContextParameter,
};
