/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import {
  ContextHelpState,
  ContextHelpActionTypes,
  SET_CONTEXT_MENU_HELP,
  SET_CONTEXT_PARAM_HELP,
  SET_CONTEXT_PARAM_WARNING,
  CLEAN_CONTEXT_PARAM,
} from './types';

const initialState: ContextHelpState = {
  menu: {
    title: '',
    text: '',
    message: undefined,
  },
  parameter: {
    title: '',
    text: '',
    message: undefined,
  },
  warning: undefined,
};

const contextHelpReducer = (
  state = initialState,
  action: ContextHelpActionTypes,
): ContextHelpState => {
  switch (action.type) {
    case SET_CONTEXT_MENU_HELP:
      return {
        ...state,
        menu: {
          ...state.menu,
          title: action.payload.title,
          text: action.payload.text,
          message: action.payload.message,
        },
      };
    case SET_CONTEXT_PARAM_HELP:
      return {
        ...state,
        parameter: {
          ...state.parameter,
          title: action.payload.title,
          text: action.payload.text,
          message: action.payload.message,
        },
      };
    case SET_CONTEXT_PARAM_WARNING:
      return {
        ...state,
        warning: action.payload,
      };
    case CLEAN_CONTEXT_PARAM:
      return {
        ...state,
        parameter: {
          ...state.parameter,
          title: '',
          text: '',
          message: '',
        },
        warning: undefined,
      };
    default:
      return state;
  }
};

export default contextHelpReducer;
