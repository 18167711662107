/* eslint-disable max-len */
import { toHex2, toHex4 } from '../../../helpers/functions';
import {
  ContactronWizardActionTypes,
  ContactronStationWizardState,
  ContactronWizardAddModuleInstanceAction,
  ContactronWizardGroupMode,
  ContactronWizardSetProcessDataCheckboxAction,
  ContactronModule,
  ContactronWizardSetAllDataAction,
  ContactronWizardSetModuleHighlightAction,
  ContactronModuleInstanceState,
} from './types';

export const contactronModuleKey = (address: number): string => `MOD${address}`;

export const moduleId = (
  vendorId: number,
  familyId: number,
  groupId: number,
  deviceId: number,
  configVersion: number,
): string => {
  const id = `${toHex2(vendorId)}-${toHex2(familyId)}-${toHex2(groupId)}-${toHex2(deviceId)}-${toHex4(configVersion)}`;
  return id.toUpperCase();
};

export const contactronModuleId = (module: ContactronModuleInstanceState)
  : string => {
  const id = moduleId(module.vendorId, module.familyId, module.groupId, module.deviceId, module.configVersion);
  return id.toUpperCase();
};

const stationInstance: ContactronModuleInstanceState = {
  address: 0,
  configVersion: 1,
  deviceId: 18,
  familyId: 1,
  groupId: 1,
  name: 'EM-PNET-GATEWAY-IFS',
  serialNo: '0',
  vendorId: 1,
};

const station: ContactronModule = {
  instance: stationInstance,
  inputDataCheckbox: {},
  outputDataCheckbox: {},
  moduleId: contactronModuleId(stationInstance),
  totalSelected: 0,
  type: 'station',
  highlight: false,
};

const initialState: ContactronStationWizardState = {
  modules: {
    MOD0: station,
  },
  moduleList: [contactronModuleKey(0)],
  selectedModule: contactronModuleKey(0),
  groupMode: ContactronWizardGroupMode.Device,
  totalSelected: 0,
  applyAllData: false,
};

const addModuleReducer = (
  state: ContactronStationWizardState,
  action: ContactronWizardAddModuleInstanceAction,
): ContactronStationWizardState => {
  const { payload } = action;
  const { module } = payload;

  const modkey = contactronModuleKey(module.address);
  const find = state.moduleList.find((mod) => mod === modkey);
  if (find !== undefined) {
    return {
      ...state,
      modules: {
        ...state.modules,
        [modkey]: {
          ...state.modules[modkey],
          instance: payload.module,
          highlight: payload.userPressButton,
        },
      },
    };
  }

  return {
    ...state,
    modules: {
      ...state.modules,
      [modkey]: {
        instance: payload.module,
        inputDataCheckbox: {},
        outputDataCheckbox: {},
        moduleId: contactronModuleId(payload.module),
        totalSelected: 0,
        highlight: true,
        type: 'module',
      },
    },
    moduleList: [
      ...state.moduleList,
      modkey,
    ],
  };
};

const setModuleHighlightReducer = (
  state: ContactronStationWizardState,
  action: ContactronWizardSetModuleHighlightAction,
): ContactronStationWizardState => {
  const { payload } = action;
  const { address, value } = payload;
  return {
    ...state,
    modules: {
      ...state.modules,
      [contactronModuleKey(address)]: {
        ...state.modules[contactronModuleKey(address)],
        highlight: value,
      },
    },
  };
};

// eslint-disable-next-line max-len
const calculateTotalSelected = (checkboxObject: { [key: string]: boolean }) => Object.values(checkboxObject).reduce((total, isChecked) => total + (isChecked ? 1 : 0), 0);

// eslint-disable-next-line max-len
const calculateTotalSelectedAllModules = (modules: { [key: string]: any }[]) => modules.reduce((total, module) => total + (module.totalSelected || 0), 0);

const setAllDataReducer = (
  state: ContactronStationWizardState,
  action: ContactronWizardSetAllDataAction,
): ContactronStationWizardState => {
  const { value, address } = action.payload;
  const key = `MOD${address}`;

  if (!value) {
    return {
      ...state,
      applyAllData: value,
    };
  }

  const selectedModule = state.modules[key];
  const selectedModuleInputDataCheckbox = selectedModule.inputDataCheckbox;
  const selectedModuleOutputDataCheckbox = selectedModule.outputDataCheckbox;

  const updatedModules = Object.keys(state.modules).reduce((acc, modId) => {
    if (modId === key || modId === 'MOD0') {
      return acc;
    }

    return {
      ...acc,
      [modId]: {
        ...acc[modId],
        inputDataCheckbox: {
          ...acc[modId].inputDataCheckbox,
          ...selectedModuleInputDataCheckbox,
        },
        outputDataCheckbox: {
          ...acc[modId].outputDataCheckbox,
          ...selectedModuleOutputDataCheckbox,
        },
        totalSelected: selectedModule.totalSelected,
      },
    };
  }, state.modules);

  const totalSelectedAllModules = calculateTotalSelectedAllModules(Object.values(updatedModules));

  return {
    ...state,
    modules: updatedModules,
    totalSelected: totalSelectedAllModules,
    applyAllData: value,
  };
};

const setModuleProcessInputDataCheckbox = (
  state: ContactronStationWizardState,
  address: number,
  name: string,
  value: boolean,
): ContactronStationWizardState => {
  const key = `MOD${address}`;

  let newState = {
    ...state,
    modules: {
      ...state.modules,
      [key]: {
        ...state.modules[key],
        inputDataCheckbox: {
          ...state.modules[key].inputDataCheckbox,
          [name]: value,
        },
      },
    },
  };

  const totalSelected = calculateTotalSelected(newState.modules[key].outputDataCheckbox)
    + calculateTotalSelected(newState.modules[key].inputDataCheckbox);
  newState = {
    ...newState,
    modules: {
      ...newState.modules,
      [key]: {
        ...newState.modules[key],
        totalSelected,
      },
    },
  };

  const totalSelectedAllModules = calculateTotalSelectedAllModules(Object.values(newState.modules));
  newState = {
    ...newState,
    totalSelected: totalSelectedAllModules,
  };
  return newState;
};

const setModuleProcessOutputDataCheckbox = (
  state: ContactronStationWizardState,
  address: number,
  name: string,
  value: boolean,
): ContactronStationWizardState => {
  const key = `MOD${address}`;
  let newState = {
    ...state,
    modules: {
      ...state.modules,
      [key]: {
        ...state.modules[key],
        outputDataCheckbox: {
          ...state.modules[key].outputDataCheckbox,
          [name]: value,
        },
      },
    },
  };

  const totalSelected = calculateTotalSelected(newState.modules[key].outputDataCheckbox)
    + calculateTotalSelected(newState.modules[key].inputDataCheckbox);
  newState = {
    ...newState,
    modules: {
      ...newState.modules,
      [key]: {
        ...newState.modules[key],
        totalSelected,
      },
    },
  };

  const totalSelectedAllModules = calculateTotalSelectedAllModules(Object.values(newState.modules));
  newState = {
    ...newState,
    totalSelected: totalSelectedAllModules,
  };
  return newState;
};

const setModuleProcessInputDataCheckboxReducer = (
  state: ContactronStationWizardState,
  action: ContactronWizardSetProcessDataCheckboxAction,
): ContactronStationWizardState => {
  const { payload } = action;
  const {
    address, name, value,
  } = payload;

  if (address === 0 || !state.applyAllData) {
    return setModuleProcessInputDataCheckbox(state, address, name, value);
  }

  return Object.keys(state.modules).reduce((acc, key) => {
    const mod = acc.modules[key];
    if (mod.type === 'station') {
      return acc;
    }
    return setModuleProcessInputDataCheckbox(acc, mod.instance.address, name, value);
  }, state);
};

const setModuleProcessOutputDataCheckboxReducer = (
  state: ContactronStationWizardState,
  action: ContactronWizardSetProcessDataCheckboxAction,
): ContactronStationWizardState => {
  const { payload } = action;
  const {
    address, name, value,
  } = payload;

  if (address === 0 || !state.applyAllData) {
    return setModuleProcessOutputDataCheckbox(state, address, name, value);
  }

  return Object.keys(state.modules).reduce((acc, key) => {
    const mod = acc.modules[key];
    if (mod.type === 'station') {
      return acc;
    }
    return setModuleProcessOutputDataCheckbox(acc, mod.instance.address, name, value);
  }, state);
};

const setModuleProcessDataCheckboxReducer = (
  state: ContactronStationWizardState,
  action: ContactronWizardSetProcessDataCheckboxAction,
): ContactronStationWizardState => {
  const { payload } = action;
  const { kind } = payload;

  return kind === 'input'
    ? setModuleProcessInputDataCheckboxReducer(state, action)
    : setModuleProcessOutputDataCheckboxReducer(state, action);
};

const station0Id = contactronModuleKey(0);

const contactronStationInitWizardReducer = (
  state = initialState,
  action: ContactronWizardActionTypes,
): ContactronStationWizardState => {
  switch (action.type) {
    case 'CONTACTRON_STATION__COMISSIONING_WIZARD__CLEANUP':
      return {
        ...state,
        modules: {
          [station0Id]: station,
        },
        moduleList: [station0Id],
        selectedModule: station0Id,
        groupMode: ContactronWizardGroupMode.Device,
        totalSelected: 0,
      };
    case 'CONTACTRON_STATION__COMISSIONING_WIZARD__ADD_MODULE':
      return addModuleReducer(state, action);
    case 'CONTACTRON_STATION__COMISSIONING_WIZARD__SET_MODULE_HIGHLIGHT':
      return setModuleHighlightReducer(state, action);
    case 'CONTACTRON_STATION__COMISSIONING_WIZARD__SET_GROUP_MODE':
      return {
        ...state,
        groupMode: action.payload,
      };
    case 'CONTACTRON_STATION__COMISSIONING_WIZARD__SET_PROCESS_DATA_CHECKBOX':
      return setModuleProcessDataCheckboxReducer(state, action);
    case 'CONTACTRON_STATION__COMISSIONING_WIZARD__SET_ALL_DATA':
      return setAllDataReducer(state, action);
    case 'CONTACTRON_STATION__COMISSIONING_WIZARD__SELECT_MODULE':
      return {
        ...state,
        selectedModule: contactronModuleKey(action.payload),
      };
    default:
      return state;
  }
};

export default contactronStationInitWizardReducer;
