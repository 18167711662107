/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import { UnsupportedControl } from '../../../../../UnsupportedControl';
import useFloatMessageContextHelp from '../../../../../ParameterEditor/VariableEditorControl/hooks/useFloatMessageContextHelp';
import NumberEditorControl from '../../../../../ParameterEditor/NumberEditorControl/NumberEditorControl';

export interface ForceAnalogEditorControlProps {
    value: number;
    statusDescriptor: DeviceModelStatus.StatusDescriptor;
    statusValue: DeviceModelStatus.StatusValue;
    unit: string | undefined;
    disabled: boolean;
    onChange: (value: string) => void;
}

export const ForceAnalogEditorControl: React.FC<ForceAnalogEditorControlProps> = (
  props: ForceAnalogEditorControlProps,
): React.ReactElement => {
  const {
    value, unit, statusDescriptor, statusValue, onChange,
    disabled,
  } = props;
  const [warningMessage, showContentHelp, hideContentHelp] = useFloatMessageContextHelp(statusDescriptor, statusValue);

  if (statusDescriptor.valueType.type !== DeviceModelStatus.StatusDescriptorValueType.FLOAT) {
    return <UnsupportedControl text="ForceAnalogEditorControl: only FLOAT type is supported" />;
  }
  const { displayFormat, stepSize, precision } = statusDescriptor.valueType;

  return (
    <NumberEditorControl
      identRef={statusDescriptor?.identRef}
      value={value}
      readonly={statusDescriptor.handling === DeviceModelStatus.StatusDescriptorHandling.READ_ONLY || disabled}
      modified={statusValue.modified}
      unit={unit}
      stepSize={stepSize}
      precision={precision}
      displayFormat={displayFormat ?? '%.2f'}
      onValueChange={onChange}
      onFocus={() => showContentHelp()}
      onBlur={() => hideContentHelp()}
      warning={warningMessage}
    />
  );
};
