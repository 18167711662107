/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../../../../../../store';
import { showApplicationMessage } from '../../../../../../../../store/applicationMessage/actions';
import { contactronWizardSetProcessDataCheckbox } from '../../../../../../../../store/wizards/contactronStation';

const totalSelectedModule = 16;
const totalSelectedAllModules = 64;

const useProcessDataCheckbox = (name: string, defaultValue: boolean, kind: 'input' | 'output') => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const totalSelected = useTypedSelector((state) => state.contactronInitWizard.totalSelected);
  const checked = useTypedSelector((state) => {
    const value = kind === 'input'
      ? state.contactronInitWizard.modules[state.contactronInitWizard.selectedModule].inputDataCheckbox[name]
      : state.contactronInitWizard.modules[state.contactronInitWizard.selectedModule].outputDataCheckbox[name];
    return value ?? defaultValue;
  });
  const totalModuleSelected = useTypedSelector((state) => state.contactronInitWizard.modules[state.contactronInitWizard.selectedModule].totalSelected);
  const address = useTypedSelector((state) => state.contactronInitWizard.modules[state.contactronInitWizard.selectedModule].instance.address);
  const applyAllData = useTypedSelector((state) => state.contactronInitWizard.applyAllData);
  const modulesCount = useTypedSelector((state) => Object.keys(state.contactronInitWizard.modules).length);

  const onValueChange = useCallback((value: boolean) => {
    let setProcess = true;
    if (value === true) {
      if (totalSelected === totalSelectedAllModules) {
        dispatch(showApplicationMessage('warning', t('WIZARD_CONTACTRON__MESSAGE__MAX_TOTAL_SELECTED_PROCESS_DATA', {
          MAX_NUMBER: totalSelectedAllModules,
        })));
        setProcess = false;
      } else if (totalModuleSelected === totalSelectedModule) {
        dispatch(showApplicationMessage('warning', t('WIZARD_CONTACTRON__MESSAGE__MODULE__MAX_SELECTED_PROCESS_DATA', {
          MAX_NUMBER: totalSelectedModule,
        })));
        setProcess = false;
      } else if (applyAllData && ((totalModuleSelected + 1) * modulesCount) > totalSelectedAllModules) {
        dispatch(showApplicationMessage('warning', t('WIZARD_CONTACTRON__MESSAGE__MAX_TOTAL_SELECTED_PROCESS_DATA', {
          MAX_NUMBER: totalSelectedAllModules,
        })));
        setProcess = false;
      }
    }
    if (setProcess) {
      dispatch(contactronWizardSetProcessDataCheckbox({
        address,
        kind,
        name,
        value,
      }));
    }
  }, [address, kind, name, totalSelected, totalSelectedAllModules, totalModuleSelected, applyAllData, modulesCount]);
  return [checked, onValueChange] as const;
};

export default useProcessDataCheckbox;
