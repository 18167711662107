/* eslint-disable max-len */

/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { Action } from 'redux';
import { ContactronModuleInstance } from '../../middleware/contactronWizard/types';

export const CONTACTRON_STATION__COMISSIONING_WIZARD__ADD_MODULE = 'CONTACTRON_STATION__COMISSIONING_WIZARD__ADD_MODULE';
export const CONTACTRON_STATION__COMISSIONING_WIZARD__CLEANUP = 'CONTACTRON_STATION__COMISSIONING_WIZARD__CLEANUP';

export const CONTACTRON_STATION__COMISSIONING_WIZARD__SET_MODULE_TAG = 'CONTACTRON_STATION__COMISSIONING_WIZARD__SET_MODULE_TAG';
export const CONTACTRON_STATION__COMISSIONING_WIZARD__SET_MODULE_HIGHLIGHT = 'CONTACTRON_STATION__COMISSIONING_WIZARD__SET_MODULE_HIGHLIGHT';

export const CONTACTRON_STATION__COMISSIONING_WIZARD__SET_GROUP_MODE = 'CONTACTRON_STATION__COMISSIONING_WIZARD__SET_GROUP_MODE';
export const CONTACTRON_STATION__COMISSIONING_WIZARD__SET_PROCESS_DATA_CHECKBOX = 'CONTACTRON_STATION__COMISSIONING_WIZARD__SET_PROCESS_DATA_CHECKBOX';
export const CONTACTRON_STATION__COMISSIONING_WIZARD__SET_ALL_DATA_CHECKBOX = 'CONTACTRON_STATION__COMISSIONING_WIZARD__SET_ALL_DATA_CHECKBOX';
export const CONTACTRON_STATION__COMISSIONING_WIZARD__SET_ALL_DATA = 'CONTACTRON_STATION__COMISSIONING_WIZARD__SET_ALL_DATA';
export const CONTACTRON_STATION__COMISSIONING_WIZARD__SELECT_MODULE = 'CONTACTRON_STATION__COMISSIONING_WIZARD__SELECT_MODULE';

export enum ContactronWizardGroupMode {
  // eslint-disable-next-line no-unused-vars
  Device = 0,
  // eslint-disable-next-line no-unused-vars
  ProcessValueName = 1,
}

export interface ContactronModuleCheckboxCollection {
  [key: string]: boolean;
}

export interface ContactronModuleInstanceState {
  address: number;
  vendorId: number;
  groupId: number;
  familyId: number;
  deviceId: number;
  serialNo: string;
  configVersion: number;
  name: string;
}

export interface ContactronModule {
  instance: ContactronModuleInstanceState;
  inputDataCheckbox: ContactronModuleCheckboxCollection;
  outputDataCheckbox: ContactronModuleCheckboxCollection;
  moduleId: string;
  totalSelected: number;
  highlight: boolean;
  type: 'station' | 'module';
}

export interface ContactronModuleCollection {
  [key: string]: ContactronModule;
}

export interface ContactronStationWizardState {
  moduleList: string[];
  modules: ContactronModuleCollection;
  selectedModule: string;
  groupMode: ContactronWizardGroupMode;
  totalSelected: number;
  applyAllData: boolean;
}

export interface ContactronWizardAddModuleInstancePayload {
  module: ContactronModuleInstance;
  stationInstanceId: string;
  userPressButton: boolean;
}

export interface ContactronWizardAddModuleInstanceAction extends Action<typeof CONTACTRON_STATION__COMISSIONING_WIZARD__ADD_MODULE> {
  payload: ContactronWizardAddModuleInstancePayload,
}

export type ContactronWizardCleanupAction = Action<typeof CONTACTRON_STATION__COMISSIONING_WIZARD__CLEANUP>

export interface ContactronWizardSetGroupModeAction extends Action<typeof CONTACTRON_STATION__COMISSIONING_WIZARD__SET_GROUP_MODE> {
  payload: ContactronWizardGroupMode;
}

export interface ContactronWizardSelectModuleAction extends Action<typeof CONTACTRON_STATION__COMISSIONING_WIZARD__SELECT_MODULE> {
  payload: number;
}

export interface CWSetProcessDataCheckboxPaload {
  address: number;
  name: string;
  kind: 'input' | 'output';
  value: boolean;
}

export interface ContactronWizardSetProcessDataCheckboxAction extends Action<typeof CONTACTRON_STATION__COMISSIONING_WIZARD__SET_PROCESS_DATA_CHECKBOX> {
  payload: CWSetProcessDataCheckboxPaload;
}

export interface CWSetAllDataPayload {
  address: number;
  value: boolean;
}

export interface ContactronWizardSetAllDataAction extends Action<typeof CONTACTRON_STATION__COMISSIONING_WIZARD__SET_ALL_DATA> {
  payload: CWSetAllDataPayload,
}

export type CWSetModuleHighlightPayload = CWSetAllDataPayload;

export interface ContactronWizardSetModuleHighlightAction extends Action<typeof CONTACTRON_STATION__COMISSIONING_WIZARD__SET_MODULE_HIGHLIGHT> {
  payload: CWSetModuleHighlightPayload;
}

export type ContactronWizardActionTypes = ContactronWizardAddModuleInstanceAction
 | ContactronWizardCleanupAction
 | ContactronWizardSetModuleHighlightAction
 | ContactronWizardSetGroupModeAction
 | ContactronWizardSetProcessDataCheckboxAction
 | ContactronWizardSetAllDataAction
 | ContactronWizardSelectModuleAction;
