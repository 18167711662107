/* eslint-disable max-len */
import { DeviceModelStatus, IdentRef } from '@gpt/commons';
import { useContext } from 'react';
import { useTypedSelector } from '../store/reduxStore';
import { DatasetState, DatasetType, DeviceDatasetValueCollection } from '../store/deviceInstances/store/deviceDataset/types';
import { DatasetContext } from '../views/DatasetContext';
import { deviceTargetDatasetSelector } from '../store/deviceInstances/store/dataStorage/selectors';

/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
const useContextDataset = (deviceInstanceId: string | undefined, target?: DatasetType): DatasetState | undefined => {
  const { targetDataset } = useContext(DatasetContext);
  return useTypedSelector((state) => deviceTargetDatasetSelector(state.deviceInstances, deviceInstanceId, target ?? targetDataset));
};

export const useContextDatasetValid = (deviceInstanceId: string | undefined, target?: DatasetType): boolean | undefined => {
  const dataset = useContextDataset(deviceInstanceId, target);
  return dataset?.datasetValid;
};

export const useContextDatasetModified = (deviceInstanceId: string | undefined, target?: DatasetType): boolean | undefined => {
  const dataset = useContextDataset(deviceInstanceId, target);
  return dataset?.datasetModified;
};

export const useContextDatasetDescriptors = (deviceInstanceId: string | undefined, target?: DatasetType): DeviceModelStatus.DeviceModelDescriptorCollection => {
  const dataset = useContextDataset(deviceInstanceId, target);
  return dataset?.descriptors ?? {};
};

export const useContextDatasetValues = (deviceInstanceId: string | undefined, target?: DatasetType): DeviceDatasetValueCollection => {
  const dataset = useContextDataset(deviceInstanceId, target);
  return dataset?.values ?? {};
};

export const useContextDatasetDescriptor = (deviceInstanceId: string | undefined, identRef: IdentRef, target?: DatasetType): DeviceModelStatus.DeviceModelDescriptor | undefined => {
  const dataset = useContextDataset(deviceInstanceId, target);
  return dataset?.descriptors[identRef];
};

export const useContextDatasetStatusDescriptor = (deviceInstanceId: string | undefined, identRef: IdentRef, target?: DatasetType): DeviceModelStatus.StatusDescriptor | undefined => {
  const dataset = useContextDataset(deviceInstanceId, target);
  const descriptor = dataset?.descriptors[identRef];
  if (descriptor?.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return undefined;
  }
  return descriptor;
};

export const useContextDatasetStringProperty = (deviceInstanceId: string | undefined, identRef: IdentRef, target?: DatasetType): string | undefined => {
  const dataset = useContextDataset(deviceInstanceId, target);
  const descriptor = dataset?.descriptors[identRef];
  if (descriptor?.type !== DeviceModelStatus.StatusType.PropertyDescriptor) {
    return undefined;
  }
  const { value } = descriptor;
  return value?.value === undefined ? undefined : `${value.value}`;
};

export const useContextDatasetNumberProperty = (deviceInstanceId: string | undefined, identRef: IdentRef, target?: DatasetType): number | undefined => {
  const dataset = useContextDataset(deviceInstanceId, target);
  const descriptor = dataset?.descriptors[identRef];
  if (descriptor?.type !== DeviceModelStatus.StatusType.PropertyDescriptor) {
    return undefined;
  }
  const { value } = descriptor;
  return value?.type === DeviceModelStatus.CustomPropertyType.Number ? value.value : undefined;
};

export const useContextDatasetStatusValue = (deviceInstanceId: string | undefined | undefined, identRef: IdentRef, target?: DatasetType): DeviceModelStatus.StatusValue | undefined => {
  const dataset = useContextDataset(deviceInstanceId, target);
  return dataset?.values[identRef];
};

export const useContextDatasetDescriptorLabel = (deviceInstanceId: string | undefined, identRef: IdentRef, target?: DatasetType):string | undefined => {
  const dataset = useContextDataset(deviceInstanceId, target);
  const desc = dataset?.descriptors[identRef];
  if (desc === undefined) {
    return undefined;
  }
  if (desc.type !== DeviceModelStatus.StatusType.StatusDescriptor) {
    return undefined;
  }
  return desc.label;
};
