/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import './ForceRadioControl.scss';
import { useTranslation } from 'react-i18next';
import { RadioEditorControl } from '../../../../ParameterEditor';

export interface ForceRadioControlProps {
  value: boolean;
  disabled: boolean;
  onChange: (value: boolean) => void;
}

export const ForceRadioControl: React.FC<ForceRadioControlProps> = (
  props: ForceRadioControlProps,
): React.ReactElement => {
  const { value, onChange, disabled } = props;
  const { t } = useTranslation();

  return (
    <div className="forceradiocontrol">
      <RadioEditorControl
        onChange={() => onChange(true)}
        id="absolute"
        group="absolute"
        label={t('FORCE_ANALOG_OUTPUT__ABSOLUTE')}
        value={value}
        readonly={disabled}
      />
      <RadioEditorControl
        onChange={() => onChange(true)}
        id="percent"
        group="percent"
        label={t('FORCE_ANALOG_OUTPUT__PERCENTAGE')}
        value={!value}
        readonly={disabled}
      />
    </div>
  );
};
