/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../../../store';
import moduleWithButton from '../../assets/module-with-button.png';
import moduleWOButton from '../../assets/module-wo-button.png';
import { CommissioningWizardState } from '../../hooks/useCommissioningWizardState';
import './StationAddressingControl.scss';

export interface StationAddressingControlProps {
  wizardState: CommissioningWizardState;
}

const StationAddressingControl: React.FC<StationAddressingControlProps> = (props: StationAddressingControlProps)
: React.ReactElement => {
  const { t } = useTranslation();
  const { wizardState } = props;
  const moduleCount = useTypedSelector((state) => Object.keys(state.contactronInitWizard.modules).length);

  // Please now press the button on device 1.
  const title = t<string>('WIZARD_CONTACTRON__STATION_ADDRESS__PRESS_BUTTON_ON_DEVICE', {
    DEVICE_NUMBER: moduleCount,
  });

  const moduleImage = wizardState === CommissioningWizardState.Page2StationAddressingScanStart ? moduleWithButton : moduleWOButton;
  const textClassname = wizardState === CommissioningWizardState.Page2StationAddressingScanFinish
    ? ' page-station-addressing-control__text--hidden'
    : '';

  return (
    <div className="page-station-addressing__control contactron-commissioning-wizard">
      <div className="page-station-addressing-control">
        <div className="page-station-addressing-control__image">
          <img className="page-station-addressing-control__image--image" src={moduleImage} alt="Phoenix Contact Splash" />
        </div>
        <div className={`page-station-addressing-control__text${textClassname}`}>
          {title}
        </div>
      </div>
    </div>
  );
};

export default StationAddressingControl;
