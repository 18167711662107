/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import GroupTable from './GroupTable/GroupTable';
import './GroupProcessDataTable.scss';

export interface GroupProcessDataTableProps {
  stationInstanceId: string;
  stationDeviceDescription: DeviceModelStatus.Wizards.ContactronStation.StationDeviceCollectionType;
}

const GroupProcessDataTable: React.FC<GroupProcessDataTableProps> = (
  props: GroupProcessDataTableProps,
) : React.ReactElement => {
  const { stationDeviceDescription, stationInstanceId } = props;
  return (
    <div className="page-station-group-process-data__table contactron-commissioning-wizard">
      <GroupTable
        kind="input"
        stationInstanceId={stationInstanceId}
        stationDeviceDescription={stationDeviceDescription}
        title="WIZARD_CONTACTRON__PAGE6_INPUT_PROCESS_DATA__TITLE"
      />
      <div className="page-station-group-process-data__table__splitter" />
      <GroupTable
        kind="output"
        stationInstanceId={stationInstanceId}
        stationDeviceDescription={stationDeviceDescription}
        title="WIZARD_CONTACTRON__PAGE6_OUTPUT_PROCESS_DATA__TITLE"
      />
    </div>
  );
};

export default GroupProcessDataTable;
