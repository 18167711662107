export const convertToAbsolute = (
  percentageValue: number,
  rangeStart: number,
  rangeEnd: number,
): number => {
  const isValidRange = rangeEnd != null && rangeStart != null && rangeEnd !== rangeStart;
  if (!isValidRange) {
    return 0;
  }

  if (rangeEnd - rangeStart === 0) {
    return 0;
  }

  const a = 1 / (rangeEnd - rangeStart);
  const b = -rangeStart * a;

  return ((percentageValue / 100) - b) / a;
};

export const convertToPercentage = (
  absoluteValue: number,
  rangeStart: number,
  rangeEnd: number,
): number => {
  const isValidRange = rangeEnd != null && rangeStart != null && rangeEnd !== rangeStart;
  if (!isValidRange) {
    return 0;
  }

  if (rangeEnd - rangeStart === 0) {
    return 0;
  }

  const a = 1 / (rangeEnd - rangeStart);
  const b = -rangeStart * a;

  return (a * absoluteValue + b) * 100;
};
