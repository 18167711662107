/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
/* eslint-disable import/prefer-default-export */
/* eslint-disable max-len */

import { Middleware, MiddlewareAPI, Dispatch } from 'redux';
import {
  DeviceInformation, DeviceModelStatus, Services, WebDevice,
} from '@gpt/commons';
import i18next from 'i18next';
import { ROOT_DEVICEINSTANCE_ID } from '../../../../helpers/createDeviceInstanceId';
import { getAppUiLanguageCode } from '../../../../i18n-config';
import { IWebWorkerDeviceManager } from '../../../../services/WebWorkerDevice/WebWorkerDeviceManager';
import { cleanupApplicationMessages, showApplicationMessage } from '../../../applicationMessage/actions';
import { ExecutionState } from '../../../common';
import { i18nWebServiceTranslationRequest } from '../../../i18nWebService/actions';
import { deviceInstancesStoreSelector, deviceDiscoveryServiceSelector } from '../../../reduxStoreSelector';
import { cleanupCxeTransferValues } from '../../../wizards/cxeTransferWizard/actions';
import {
  createDeviceInstance,
  removeAllDeviceInstances,
  selectDeviceInstance,
} from '../../actions';
import { setActiveDeviceExecutionStatus } from '../../store/activeDevice/actions';
import { DatasetType } from '../../store/deviceDataset/types';
import { setDeviceMethodExecutionState } from '../../store/deviceMethod/actions';
import { MethodStageExecutionStatus } from '../../store/deviceMethod/types';
import { setWebWorkerConnectionStatus } from '../connectionStatus/actions';
import {
  LoadExternalDatasetResult,
  parseExternalDataset,
  prepareDatasetFromExternalValues,
} from '../helpers/webDeviceExternalFile';
import { storeLatestDeviceType } from './actions';
import { typeDeviceInstantiationMiddlewareActions } from './types';
import { cleanDeviceActionExecutionState } from '../../store/deviceActionState/actions';
import { createExecuteMethodRequest } from './webWorkerActions';

export const dispatchLoadingExternalFileProgress = (
  deviceInstanceId: string,
  dispatch: Dispatch,
  methodIdent: string,
  stage: MethodStageExecutionStatus,
  message?: string,
): void => {
  dispatch(setDeviceMethodExecutionState(deviceInstanceId, {
    methodIdent, stage, steps: [], message,
  }));
};

export const webWorkerDeviceInstantiationMiddleware = (webWorkerDeviceManager: IWebWorkerDeviceManager): Middleware => (api: MiddlewareAPI) => (next: Dispatch) => async <A extends typeDeviceInstantiationMiddlewareActions>(action: A): Promise<A> => {
  const actionDisposeWebWorkerDevice = async (deviceInstanceId: string): Promise<void> => {
    const deviceInstances = deviceInstancesStoreSelector(api.getState());
    const webWorkerInstance = deviceInstances.instances[deviceInstanceId]?.activeDevice.modelInstance?.webWorkerInstanceRef;
    if (webWorkerInstance === undefined) {
      return;
    }

    await webWorkerDeviceManager.dispose(webWorkerInstance);
    // hide app message
    api.dispatch(removeAllDeviceInstances());
    api.dispatch(cleanupApplicationMessages());
    api.dispatch(cleanupCxeTransferValues());
  };

  const actionInstantiateWebWorkerDevice = async (
    webWorkerInstanceRef: string,
    deviceInstanceId: string,
    device: DeviceInformation,
    wizardMode: boolean,
    connectionString?: string,
  ): Promise<WebDevice.WebDeviceInitResponse | WebDevice.WebDeviceErrorResponse> => {
    api.dispatch(setActiveDeviceExecutionStatus(deviceInstanceId, ExecutionState.pending));
    let res: WebDevice.WebDeviceInitResponse | WebDevice.WebDeviceErrorResponse = {
      kind: 'WEBDEVICE__ERROR_RESPONSE',
      message: '',
      requestId: '',
      deviceInstanceId,
    };

    // Initialize webworker device model
    try {
      res = await webWorkerDeviceManager.init(webWorkerInstanceRef, deviceInstanceId, device, wizardMode, connectionString);
    } catch (err: any) {
      res = {
        kind: 'WEBDEVICE__ERROR_RESPONSE',
        message: err.message ?? 'Cannot instatiate device model',
        deviceInstanceId: '',
        requestId: '',
      };
    }

    if (res.kind === 'WEBDEVICE__ERROR_RESPONSE') {
      api.dispatch(setActiveDeviceExecutionStatus(deviceInstanceId, ExecutionState.failed));
      return res;
    }

    // save instantiated device in sessionStorage to enable implicit re-activation
    api.dispatch(storeLatestDeviceType(device));
    api.dispatch(i18nWebServiceTranslationRequest({
      language: getAppUiLanguageCode(),
      namespace: device.catalog.i18n.family,
    }));

    const { dictionaries, deviceId } = res.i18n;
    Object.keys(dictionaries)
      .forEach((lng) => i18next.addResourceBundle(lng, deviceId, dictionaries[lng]));
    await i18next.reloadResources();

    api.dispatch(createDeviceInstance(deviceInstanceId, {
      dataset: {
        descriptors: res.dataset.descriptors,
        values: res.dataset.values,
      },
      device,
      i18n: res.i18n,
      webworkerInstanceId: webWorkerInstanceRef,
    }));

    api.dispatch(setActiveDeviceExecutionStatus(deviceInstanceId, ExecutionState.pending));
    api.dispatch(selectDeviceInstance(deviceInstanceId));

    const webWorkerDevice = webWorkerDeviceManager.get(webWorkerInstanceRef);
    if (webWorkerDevice === undefined) {
      api.dispatch(setActiveDeviceExecutionStatus(deviceInstanceId, ExecutionState.failed));
      return {
        kind: 'WEBDEVICE__ERROR_RESPONSE',
        message: `Device model instance ${deviceInstanceId} not available`,
        deviceInstanceId,
        requestId: '',
      };
    }
    // Initialize write upload values to Init and User dataset
    const method = createExecuteMethodRequest(
      deviceInstanceId,
      DeviceModelStatus.Methods.METHOD_UPLOAD__IDENTREF,
      'DEVICE_INSTANTIATION__UPLOAD_ACTION',
    );
    const uploadResponse = await webWorkerDevice.get(DatasetType.device)
      .executeMethod(method);

    const { response } = uploadResponse;
    if (response.type === 'WEBDEVICE__METHOD_EXECUTE__DONE__SUCCESS') {
      // Initialize user dataset
      await webWorkerDevice.get(DatasetType.user).writeUploadValues(response.data ?? []);
      // Initialize init dataset
      await webWorkerDevice.get(DatasetType.init).writeUploadValues(response.data ?? []);

      api.dispatch(setActiveDeviceExecutionStatus(deviceInstanceId, ExecutionState.success));
      api.dispatch(cleanDeviceActionExecutionState(deviceInstanceId, 'DEVICE_INSTANTIATION__UPLOAD_ACTION'));
      api.dispatch(setWebWorkerConnectionStatus(deviceInstanceId, Services.DeviceModelServer.ConnectionService.ConnectionStatus.connected));
    } else {
      api.dispatch(setActiveDeviceExecutionStatus(deviceInstanceId, ExecutionState.failed));
      api.dispatch(setWebWorkerConnectionStatus(deviceInstanceId, Services.DeviceModelServer.ConnectionService.ConnectionStatus.disconnected));
    }
    return res;
  };

  const detectDevice = (loadedFile: LoadExternalDatasetResult): LoadExternalDatasetResult | undefined => {
    if (loadedFile.dataset === undefined) {
      return undefined;
    }

    if ((loadedFile as any).version !== undefined) {
      return loadedFile;
    }
    // We have deal with old version (without file name)
    const discoveryServiceState = deviceDiscoveryServiceSelector(api.getState());
    const deviceList = Object.keys(discoveryServiceState.catalog.deviceList).map((key) => discoveryServiceState.catalog.deviceList[key]);
    const deviceDriverId = loadedFile.dataset?.header.device.catalog.deviceDriverId.toLocaleLowerCase();

    const catalog = deviceList
      .find(
        (item) => item.deviceDriverId.toLocaleLowerCase() === deviceDriverId,
      );
    if (catalog) {
      return {
        ...loadedFile,
        dataset: {
          ...loadedFile.dataset,
          header: {
            ...loadedFile.dataset.header,
            device: {
              ...loadedFile.dataset.header.device,
              catalog,
            },
          },
        },
      };
    }

    console.log('Device not found in catalog', loadedFile.dataset?.header.device);
    return undefined;
  };

  const actionInstantiateDeviceWithFile = async (webWorkerInstanceRef: string, deviceInstanceId: string, externalFilename: string, externalFileContent: string, methodId: string) => {
    dispatchLoadingExternalFileProgress(deviceInstanceId, api.dispatch, methodId, MethodStageExecutionStatus.Initialize);
    // Load external file
    dispatchLoadingExternalFileProgress(deviceInstanceId, api.dispatch, methodId, MethodStageExecutionStatus.InProgress);
    const loadedFileTmp = await parseExternalDataset(externalFilename, externalFileContent);

    const loadedFile = detectDevice(loadedFileTmp);
    if (loadedFile === undefined) {
      const errorMessage = `Cannot convert "${externalFilename}" file`;
      dispatchLoadingExternalFileProgress(deviceInstanceId, api.dispatch, methodId, MethodStageExecutionStatus.DoneFailed, errorMessage);
      api.dispatch(showApplicationMessage('error', 'LOAD_PARAMETER_FILE__LOAD_DATA__ERROR__CANNOT_READ_CONTENT'));
      return;
    }

    if (loadedFile.dataset === undefined) {
      const errorMessage = `Failed to load external file "${externalFilename}": "${loadedFile.errorMessage}"`;
      dispatchLoadingExternalFileProgress(deviceInstanceId, api.dispatch, methodId, MethodStageExecutionStatus.DoneFailed, errorMessage);
      api.dispatch(showApplicationMessage('error', 'LOAD_PARAMETER_FILE__LOAD_DATA__ERROR__CANNOT_READ_CONTENT'));
      return;
    }

    // instantiate offline device model for device type which is specified in external file
    const res = await actionInstantiateWebWorkerDevice(webWorkerInstanceRef, deviceInstanceId, {
      catalog: loadedFile.dataset.header.device.catalog,
      instance: undefined,
    }, false);

    if (res.kind === 'WEBDEVICE__ERROR_RESPONSE') {
      const errorMessage = `Failed to load external file "${externalFilename}": "${res.message}"`;
      dispatchLoadingExternalFileProgress('', api.dispatch, methodId, MethodStageExecutionStatus.DoneFailed, errorMessage);
      api.dispatch(showApplicationMessage('error', res.message));
      return;
    }

    try {
      const deviceInstances = deviceInstancesStoreSelector(api.getState());
      const userDataset = deviceInstances.instances[deviceInstanceId]?.deviceDataset.user;
      const { values, descriptors } = userDataset;
      // device model is instantiated and dataset variables are initialized with default values
      const statusRefs = prepareDatasetFromExternalValues(loadedFile.dataset.values, values, descriptors);

      const webWorkerDevice = webWorkerDeviceManager.get(deviceInstanceId);
      if (webWorkerDevice === undefined) {
        const errorMessage = `Device instance ${deviceInstanceId} not available`;
        dispatchLoadingExternalFileProgress('', api.dispatch, methodId, MethodStageExecutionStatus.DoneFailed, errorMessage);
        api.dispatch(showApplicationMessage('error', errorMessage));
        return;
      }

      await webWorkerDevice.get(DatasetType.user).writeUploadValues(statusRefs);
      dispatchLoadingExternalFileProgress(deviceInstanceId, api.dispatch, methodId, MethodStageExecutionStatus.DoneSuccess);

      api.dispatch(showApplicationMessage('success', 'LOAD_PARAMETER_FILE__LOAD_DATA__SUCCESS'));
    } catch (error: any) {
      const errorMessage = `Device instantiation by external file "${externalFilename}" failed: "${error}"`;
      dispatchLoadingExternalFileProgress(deviceInstanceId, api.dispatch, methodId, MethodStageExecutionStatus.DoneFailed, errorMessage);
      api.dispatch(showApplicationMessage('error', error.message));
    }
  };

  try {
    switch (action.type) {
      case 'DEVICE_INSTANTIATION__INSTANTIATE_DEVICE': {
        const { payload } = action;
        const {
          deviceInfo, targetInstance, webWorkerInstanceRef, connectionString,
          wizardMode,
        } = payload;
        const targetInstanceId = targetInstance ?? ROOT_DEVICEINSTANCE_ID;
        const webWorkerInstanceId = webWorkerInstanceRef ?? ROOT_DEVICEINSTANCE_ID;
        await actionInstantiateWebWorkerDevice(webWorkerInstanceId, targetInstanceId, deviceInfo, wizardMode, connectionString);
        break;
      }
      case 'DEVICE_INSTANTIATION__INSTANTIATE_DEVICE__OFFLINE__EXTERNAL_FILE': {
        const { payload } = action;
        const { content, targetInstance } = payload;
        const targetInstanceId = targetInstance ?? ROOT_DEVICEINSTANCE_ID;
        await actionInstantiateDeviceWithFile(ROOT_DEVICEINSTANCE_ID, targetInstanceId, content.filename, content.content, content.methodId);
        break;
      }
      case 'DEVICE_INSTANTIATION__DISPOSE_DEVICE': {
        const { payload } = action;
        await actionDisposeWebWorkerDevice(payload);
      }
        break;
      default:
    }
  } catch (error) {
    console.log('deviceInstantiationMiddleware failed', error);
  }

  const result = next(action);
  return result;
};
