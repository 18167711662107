/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import { useTypedSelector } from '../../../../store';
import { UnsupportedControl } from '../../../UnsupportedControl';
import { deviceDescriptorSelector, deviceStatusValueSelector } from '../../../../store/deviceInstances/store/deviceDataset/selector';
import { DatasetType } from '../../../../store/deviceInstances/store/deviceDataset/types';
import { ForceAnalogOutputControl } from './ForceAnalogOutputControl/ForceAnalogOutputControl';

export interface ForceAnalogOutputReduxControlProps {
  deviceInstanceId: string;
  forceAnalogOutput: DeviceModelStatus.UI.SPort.typeForceAnalogOutput;
  disabled: boolean;
}

const ForceAnalogOutputReduxControl: React.FC<ForceAnalogOutputReduxControlProps> = (props: ForceAnalogOutputReduxControlProps):
  React.ReactElement => {
  const {
    forceAnalogOutput,
    deviceInstanceId,
    disabled,
  } = props;

  const {
    label, subLabel, button, variable, varRangeStart, varRangeEnd, type,
  } = forceAnalogOutput;
  const varDescriptor = useTypedSelector((state) => deviceDescriptorSelector(state.deviceInstances, deviceInstanceId, DatasetType.user, variable));
  const deviceStatusValue = useTypedSelector((state) => deviceStatusValueSelector(state.deviceInstances, deviceInstanceId, DatasetType.user, variable));

  const deviceRangeStartStatusValue = useTypedSelector(
    (state) => deviceStatusValueSelector(state.deviceInstances, deviceInstanceId, DatasetType.user, varRangeStart)?.value ?? 0,
  );
  const deviceRangeEndStatusValue = useTypedSelector(
    (state) => deviceStatusValueSelector(state.deviceInstances, deviceInstanceId, DatasetType.user, varRangeEnd)?.value ?? 0,
  );

  if (varDescriptor?.type !== DeviceModelStatus.StatusType.StatusDescriptor || deviceStatusValue === undefined) {
    return <UnsupportedControl text="ForceAnalogOutputReduxControl: undefined object" />;
  }

  return (
    <ForceAnalogOutputControl
      title={label}
      text={subLabel}
      button={button}
      type={type}
      deviceInstanceId={deviceInstanceId}
      statusDescriptor={varDescriptor}
      statusValue={deviceStatusValue}
      rangeStartValue={deviceRangeStartStatusValue}
      rangeEndValue={deviceRangeEndStatusValue}
      disabled={disabled}
    />
  );
};

export default ForceAnalogOutputReduxControl;
