/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { useDispatch } from 'react-redux';
import { DeviceModelStatus } from '@gpt/commons';
import { useTranslation } from 'react-i18next';
import { ProgressBar } from '../../../../../../components/ProgressBar/ProgressBar';
import SelectProcessCheckbox from '../SelectProcessCheckbox/SelectProcessCheckbox';
import { useTypedSelector } from '../../../../../../store';
import './DeviceProcessData.scss';
import { ProcessDataInputOutput } from '../ProcessDataInputOutput/ProcessDataInputOutput';
import { contactronWizardSetAllData } from '../../../../../../store/wizards/contactronStation';
import { showApplicationMessage } from '../../../../../../store/applicationMessage/actions';

const convertToPercentage = (selectedCount: number): number => (selectedCount / 16) * 100;

export interface DeviceProcessDataProps {
  moduleDesc: DeviceModelStatus.Wizards.ContactronStation.StationDeviceType;
}

export const DeviceProcessData: React.FC<DeviceProcessDataProps> = (props: DeviceProcessDataProps):
React.ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { moduleDesc } = props;
  const applyAllData = useTypedSelector((state) => state.contactronInitWizard.applyAllData);
  const totalModuleSelected = useTypedSelector((state) => state.contactronInitWizard.modules[state.contactronInitWizard.selectedModule].totalSelected);
  const percent = convertToPercentage(totalModuleSelected);

  const totalSelectedAllModules = 64;
  const modules = useTypedSelector((state) => state.contactronInitWizard.modules);
  const address = useTypedSelector((state) => state.contactronInitWizard.modules[state.contactronInitWizard.selectedModule].instance.address);

  const countOfModules = Object.values(modules).reduce(
    (acc, module) => (module.type === 'module' ? acc + 1 : acc),
    0,
  );

  return (
    <div className="device-process-data-container">
      <div className="device-process-data-container__header">
        <div>
          <strong>{t('WIZARD_CONTACTRON__PAGE5_SELECT_DEVICE_PROCESS_DATA')}</strong>
          {' '}
          (max. 16)
        </div>
      </div>
      <div className="device-process-data-container__device_progress">
        {t('WIZARD_CONTACTRON__PAGE5_PROCESS_DATA_PER_DEVICE')}
        {' '}
        {totalModuleSelected}
        {' '}
        / 16
        <div className="device-process-data-container__device_progress-bar">
          <ProgressBar percent={percent} />
        </div>
      </div>
      <div className="device-process-data-container__select">
        <ProcessDataInputOutput moduleDescription={moduleDesc} />
      </div>
      <div className="device-process-data-container__apply-for-all-devices">
        <SelectProcessCheckbox
          checked={applyAllData}
          label={t('WIZARD_CONTACTRON__PAGE5_APPLY_PROCESS_DATA_FOR_ALL')}
          onChange={(value) => {
            let setAllData = true;
            if (value === true && (totalModuleSelected * countOfModules) > totalSelectedAllModules) {
              dispatch(showApplicationMessage('warning', t('WIZARD_CONTACTRON__MESSAGE__MAX_TOTAL_SELECTED_PROCESS_DATA', {
                MAX_NUMBER: totalSelectedAllModules,
              })));
              setAllData = false;
            }
            if (setAllData) {
              dispatch(contactronWizardSetAllData({ address, value }));
            }
          }}
        />
      </div>
    </div>
  );
};
