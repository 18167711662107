/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useContext, useState } from 'react';
import './ForceAnalogEditor.scss';
import { useTranslation } from 'react-i18next';
import { DeviceModelStatus } from '@gpt/commons';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { ForceDialogControl } from '../../../ForceDialogControl/ForceDialogControl';
import { ForceAnalogEditorControl } from './ForceAnalogEditorControl/ForceAnalogEditorControl';
import { sPortMinMCRWriteAction } from '../../../../../store/deviceInstances/middleware/deviceMethod';
import { updateDeviceDatasetValues } from '../../../../../store/deviceInstances/store/deviceDataset';
import { DatasetType } from '../../../../../store/deviceInstances/store/deviceDataset/types';
import { ForceDialogContext, ForceDialogPasswordContext } from '../../../Context/ForceDialogContext';

export interface ForceAnalogEditorProps {
  value: number;
  statusDescriptor: DeviceModelStatus.StatusDescriptor;
  statusValue: DeviceModelStatus.StatusValue;
  deviceInstanceId: string;
  unit: string | undefined;
  disabled: boolean;
  actionId: string;
  button: DeviceModelStatus.UI.SPort.ForceAnalogOutputButton;
  onValueChange: (value: number) => void;
}

export const ForceAnalogEditor: React.FC<ForceAnalogEditorProps> = (
  props: ForceAnalogEditorProps,
): React.ReactElement => {
  const {
    value, actionId, button, unit, deviceInstanceId, disabled,
    statusDescriptor, statusValue, onValueChange,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enableWarningDialog, setEnableWarningDialog } = useContext(ForceDialogContext);
  const { password } = useContext(ForceDialogPasswordContext);

  const [showDialog, setShowDialog] = useState(false);
  const [enabled, setEnabled] = useState(true);

  return (
    <div className="forceanalogeditor-container">
      <div className="forceanalogeditor-var">
        <div className="forceanalogeditor-input">
          <ForceAnalogEditorControl
            value={value}
            statusDescriptor={statusDescriptor}
            statusValue={statusValue}
            disabled={disabled}
            unit={unit}
            onChange={(originalValue) => {
              const parsedValue = parseFloat(originalValue);
              if (Number.isNaN(parsedValue)) {
                dispatch(updateDeviceDatasetValues(deviceInstanceId, {
                  targetDataset: DatasetType.user,
                  values: [{
                    value: originalValue,
                    backupValue: statusValue.backupValue,
                    identRef: statusValue.identRef,
                    valueValidity: DeviceModelStatus.StatusValueValidity.invalidDataFormat,
                    valueValidityDescription: `${t<string>('PARAMETER_VALIDATION_ERROR__INVALID_NUMERIC_FORMAT')}`,
                  }],
                }));
              } else {
                onValueChange(parsedValue);
              }
            }}
          />
        </div>
        <Button
          className="forceanalogeditor-button"
          variant="secondary"
          onClick={() => {
            if (enableWarningDialog) {
              setShowDialog(true);
            } else {
              dispatch(sPortMinMCRWriteAction({
                actionId,
                targetInstance: deviceInstanceId,
                memoryAddress: button.address,
                ui16value: statusValue.value * button.scale,
                password,
              }));
            }
          }}
          disabled={disabled || !enabled}
        >
          {t<string>(button.label)}
        </Button>
      </div>
      <ForceDialogControl
        title={button.title}
        message={button.message}
        actionId={actionId}
        showDialog={showDialog}
        deviceInstanceId={deviceInstanceId}
        onAccept={() => {
          setEnabled(false);
          dispatch(sPortMinMCRWriteAction({
            actionId,
            targetInstance: deviceInstanceId,
            memoryAddress: button.address,
            ui16value: statusValue.value * button.scale,
            password,
          }));
          setEnableWarningDialog(false);
          setShowDialog(false);
        }}
        onCancel={() => {
          setShowDialog(false);
        }}
        onFailed={() => {
          setEnabled(true);
        }}
        onSuccess={() => {
          setEnabled(true);
        }}
      />
    </div>
  );
};
