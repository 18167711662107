/* eslint-disable import/prefer-default-export */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { contactronWizardModulePayload } from '../../middleware/contactronWizard/types';
import {
  CWSetAllDataPayload,
  CWSetProcessDataCheckboxPaload,
  ContactronWizardAddModuleInstanceAction,
  ContactronWizardCleanupAction,
  ContactronWizardGroupMode,
  ContactronWizardSelectModuleAction,
  ContactronWizardSetAllDataAction,
  ContactronWizardSetGroupModeAction,
  ContactronWizardSetModuleHighlightAction,
  ContactronWizardSetProcessDataCheckboxAction,
} from './types';

export const contactronWizardAddModuleInstance = (payload: contactronWizardModulePayload)
: ContactronWizardAddModuleInstanceAction => ({
  type: 'CONTACTRON_STATION__COMISSIONING_WIZARD__ADD_MODULE',
  payload,
});

export const contactronWizardCleanup = ()
  : ContactronWizardCleanupAction => ({
  type: 'CONTACTRON_STATION__COMISSIONING_WIZARD__CLEANUP',
});

export const contactronWizardSetGroupMode = (mode: ContactronWizardGroupMode)
  : ContactronWizardSetGroupModeAction => ({
  type: 'CONTACTRON_STATION__COMISSIONING_WIZARD__SET_GROUP_MODE',
  payload: mode,
});

export const contactronWizardSetProcessDataCheckbox = (payload: CWSetProcessDataCheckboxPaload)
  : ContactronWizardSetProcessDataCheckboxAction => ({
  type: 'CONTACTRON_STATION__COMISSIONING_WIZARD__SET_PROCESS_DATA_CHECKBOX',
  payload,
});

export const contactronWizardSetAllData = (payload: CWSetAllDataPayload)
  : ContactronWizardSetAllDataAction => ({
  type: 'CONTACTRON_STATION__COMISSIONING_WIZARD__SET_ALL_DATA',
  payload,
});

export const contactronWizardSelectModule = (payload: number)
  : ContactronWizardSelectModuleAction => ({
  type: 'CONTACTRON_STATION__COMISSIONING_WIZARD__SELECT_MODULE',
  payload,
});

export const contactronWizardSetModuleHighlight = (address: number, highlight: boolean)
  : ContactronWizardSetModuleHighlightAction => ({
  type: 'CONTACTRON_STATION__COMISSIONING_WIZARD__SET_MODULE_HIGHLIGHT',
  payload: {
    address,
    value: highlight,
  },
});
