/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../../../../../store';
import { ContactronModuleCollection, ContactronWizardGroupMode } from '../../../../../../store/wizards/contactronStation';
import GroupTableRow, { RowData } from './GroupTableRow/GroupTableRow';
import '../GroupProcessDataTable.scss';

const groupData = (
  modules: ContactronModuleCollection,
  devices: DeviceModelStatus.Wizards.ContactronStation.StationDeviceCollectionType,
  kind: 'input' | 'output',
  moduleType: 'station' | 'module',
): RowData[] => Object.entries(modules).reduce((acc: RowData[], [, module]) => {
  if (module.type !== moduleType) {
    return acc;
  }

  const device = devices[module.moduleId];
  if (!device) {
    return acc;
  }

  const moduleCheckList = kind === 'input' ? device.inputDataList : device.outputDataList;
  const moduleCheckboxes = kind === 'input' ? module.inputDataCheckbox : module.outputDataCheckbox;
  const deviceDataCheckboxDesc = kind === 'input' ? device.inputDataDescription : device.outputDataDescription;

  const deviceTagVarName = moduleType === 'station' ? 'IFSM_ADDR_APPL_AKZ' : `DEVICE${module.instance.address}_IFSM_ADDR_AKZ`;

  const checkboxList = moduleCheckList.reduce((xacc, processValueName) => {
    const checkboxValue = moduleCheckboxes[processValueName] ?? deviceDataCheckboxDesc[processValueName]?.checked;
    if (checkboxValue !== true) {
      return xacc;
    }

    const processValueDesc = deviceDataCheckboxDesc[processValueName];
    return [
      ...xacc,
      {
        deviceTagVarName,
        processValueLabel: processValueDesc.label,
        ifsAddress: module.instance.address,
        deviceType: module.instance.name,
        processValueAddress: processValueDesc.address,
        key: `${kind}-${module.instance.address}-${module.instance.serialNo}-${processValueDesc.name}`,
      }];
  }, [] as RowData[]);

  return [
    ...acc,
    ...checkboxList,
  ];
}, [] as RowData[]);

const groupByProcessValueNames = (
  modules: ContactronModuleCollection,
  devices: DeviceModelStatus.Wizards.ContactronStation.StationDeviceCollectionType,
  kind: 'input' | 'output',
  moduleType: 'station' | 'module',
) => {
  const data = groupData(modules, devices, kind, moduleType);
  return data.sort((a, b) => a.processValueAddress - b.processValueAddress);
};

const groupByDevices = (
  modules: ContactronModuleCollection,
  devices: DeviceModelStatus.Wizards.ContactronStation.StationDeviceCollectionType,
  kind: 'input' | 'output',
  moduleType: 'station' | 'module',
) => groupData(modules, devices, kind, moduleType);

export interface GroupTableProps {
  title: string;
  kind: 'input' | 'output';
  stationDeviceDescription: DeviceModelStatus.Wizards.ContactronStation.StationDeviceCollectionType;
  stationInstanceId: string;
}

const GroupTable: React.FC<GroupTableProps> = (props: GroupTableProps): React.ReactElement => {
  const {
    stationDeviceDescription, title, kind, stationInstanceId,
  } = props;
  const { t } = useTranslation();
  const moduleCollection = useTypedSelector((state) => state.contactronInitWizard.modules);
  const groupMode = useTypedSelector((state) => state.contactronInitWizard.groupMode);

  const stationRowsData = groupByDevices(moduleCollection, stationDeviceDescription, kind, 'station');

  const rowsData = groupMode === ContactronWizardGroupMode.Device
    ? groupByDevices(moduleCollection, stationDeviceDescription, kind, 'module')
    : groupByProcessValueNames(moduleCollection, stationDeviceDescription, kind, 'module');

  const moduleRows = [...stationRowsData, ...rowsData]
    .map((row, index) => (<GroupTableRow key={row.key} index={index + 1} row={row} stationInstanceId={stationInstanceId} />));

  return (
    <>
      <div className="page-group-process-data-table-title">
        <div className="page-group-process-data-table-title-text">{t(title)}</div>
      </div>
      <table className="page-group-process-data-table">
        <thead className="page-group-process-data-table-header">
          <tr>
            <th>{t('WIZARD_CONTACTRON__TABLE__HEADER_POSITION')}</th>
            <th>{t('WIZARD_CONTACTRON__TABLE__HEADER_DEVICE_TAG')}</th>
            <th>{t('WIZARD_CONTACTRON__TABLE__HEADER_IFS_ADDRESS')}</th>
            <th>{t('WIZARD_CONTACTRON__TABLE__HEADER_PROCESS_VALUE_NAME')}</th>
            <th>{t('WIZARD_CONTACTRON__TABLE__HEADER_DEVICE_TYPE')}</th>
          </tr>
        </thead>
        <tbody>
          {moduleRows}
        </tbody>
      </table>
    </>
  );
};

export default GroupTable;
