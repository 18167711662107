/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import { DeviceModelStatus } from '@gpt/commons';
import IconSwitchOpen from '../../../../assets/icons/icon-switch-open.svg';
import IconSwitchClose from '../../../../assets/icons/icon-switch-close.svg';
import { sPortMinMCRWriteAction } from '../../../../store/deviceInstances/middleware/deviceMethod';
import { ForceDialogControl } from '../../ForceDialogControl/ForceDialogControl';
import { ForceDialogContext, ForceDialogPasswordContext } from '../../Context/ForceDialogContext';
import './ForceSwitchOutputControl.scss';

const actionId = 's-port--mini-mcr--force-switch';

export interface ForceSwitchOutputControlProps {
  title: string;
  text: string;
  deviceInstanceId: string;
  disabled: boolean;
  buttonOn: DeviceModelStatus.UI.SPort.ForceSwitchOutputButton;
  buttonOff: DeviceModelStatus.UI.SPort.ForceSwitchOutputButton;
}

// eslint-disable-next-line no-unused-vars
enum RelayState {
  // eslint-disable-next-line no-unused-vars
  open = 0,
  // eslint-disable-next-line no-unused-vars
  closed = 1,
  // eslint-disable-next-line no-unused-vars
  waitingOpen = 2,
  // eslint-disable-next-line no-unused-vars
  waitingClose = 3,
}

const getIcon = (state: RelayState) => {
  if (state === RelayState.open) {
    return IconSwitchOpen;
  } if (state === RelayState.closed) {
    return IconSwitchClose;
  } if (state === RelayState.waitingOpen) {
    return IconSwitchClose;
  }
  return IconSwitchOpen;
};

const isOnButton = (state: RelayState): boolean => {
  if (state === RelayState.waitingOpen) {
    return false;
  } if (state === RelayState.waitingClose) {
    return true;
  } if (state === RelayState.open) {
    return true;
  }
  return false;
};

export const ForceSwitchOutputControl: React.FC<ForceSwitchOutputControlProps> = (
  props: ForceSwitchOutputControlProps,
): React.ReactElement => {
  const {
    title, text, buttonOn, buttonOff,
    deviceInstanceId, disabled,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { password } = useContext(ForceDialogPasswordContext);
  const { enableWarningDialog, setEnableWarningDialog } = useContext(ForceDialogContext);
  const [showDialog, setShowDialog] = useState(false);
  const [relayState, setRelayState] = useState(RelayState.open);

  const forceButton = isOnButton(relayState) ? buttonOn : buttonOff;
  const icon = getIcon(relayState);

  return (
    <div className="forceswitchingoutput-container">
      <div className="forceswitchingoutput-title">{t(title)}</div>
      <div className="forceswitchingoutput-text">{t(text)}</div>
      <div className="forceswitchingoutput-buttons-icon">
        <div className="forceswitchingoutput-buttons">
          <Button
            className="forceswitchingoutput-button"
            variant="secondary"
            onClick={() => {
              setRelayState(RelayState.waitingClose);
              if (enableWarningDialog) {
                setShowDialog(true);
              } else {
                dispatch(sPortMinMCRWriteAction({
                  actionId,
                  targetInstance: deviceInstanceId,
                  memoryAddress: forceButton.address,
                  ui16value: forceButton.value,
                  password,
                }));
              }
            }}
            disabled={disabled || relayState !== RelayState.open}
          >
            {t(buttonOn.label)}
          </Button>
          <Button
            className="forceswitchingoutput-button"
            variant="secondary"
            onClick={() => {
              setRelayState(RelayState.waitingOpen);
              if (enableWarningDialog) {
                setShowDialog(true);
              } else {
                dispatch(sPortMinMCRWriteAction({
                  actionId,
                  targetInstance: deviceInstanceId,
                  memoryAddress: forceButton.address,
                  ui16value: forceButton.value,
                  password,
                }));
              }
            }}
            disabled={disabled || relayState !== RelayState.closed}
          >
            {t(buttonOff.label)}
          </Button>
        </div>
        <img
          className="forceswitchingoutput-icon"
          src={icon}
          alt="switch"
        />
        <ForceDialogControl
          title={forceButton.title}
          message={forceButton.message}
          actionId={actionId}
          showDialog={showDialog}
          deviceInstanceId={deviceInstanceId}
          onAccept={() => {
            setEnableWarningDialog(false);
            dispatch(sPortMinMCRWriteAction({
              actionId,
              targetInstance: deviceInstanceId,
              memoryAddress: forceButton.address,
              ui16value: forceButton.value,
              password,
            }));
            setShowDialog(false);
          }}
          onCancel={() => {
            setShowDialog(false);
            setRelayState((prevState) => {
              if (prevState === RelayState.waitingOpen) {
                return RelayState.closed;
              } if (prevState === RelayState.waitingClose) {
                return RelayState.open;
              }
              return prevState;
            });
          }}
          onFailed={() => {
            setRelayState((prevState) => {
              if (prevState === RelayState.waitingOpen) {
                return RelayState.closed;
              } if (prevState === RelayState.waitingClose) {
                return RelayState.open;
              }
              return prevState;
            });
          }}
          onSuccess={() => {
            setRelayState((prevState) => {
              if (prevState === RelayState.waitingOpen) {
                return RelayState.open;
              } if (prevState === RelayState.waitingClose) {
                return RelayState.closed;
              }
              return prevState;
            });
          }}
        />
      </div>
    </div>
  );
};
