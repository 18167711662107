/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import React, { useState } from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import { useDispatch } from 'react-redux';
import { ForceRadioControl } from '../ForceRadioControl/ForceRadioControl';
import { convertToAbsolute, convertToPercentage } from '../ForceAnalogEditor/ForceAnalogEditorControl/functions';
import { writeActiveDeviceVariableValues } from '../../../../../store';
import { ForceAnalogEditor } from '../ForceAnalogEditor/ForceAnalogEditor';

const actionId = 's-port--mini-mcr--force-analog-absolute';

export interface ForceAnalogAbsoluteOutputProps {
  statusDescriptor: DeviceModelStatus.StatusDescriptor;
  statusValue: DeviceModelStatus.StatusValue;
  rangeStartValue: number;
  rangeEndValue: number;
  button: DeviceModelStatus.UI.SPort.ForceAnalogOutputButton;
  deviceInstanceId: string;
  unit: string | undefined;
  disabled: boolean;
}

export const ForceAnalogAbsoluteOutput: React.FC<ForceAnalogAbsoluteOutputProps> = (
  props: ForceAnalogAbsoluteOutputProps,
): React.ReactElement => {
  const {
    statusDescriptor, statusValue, button, unit,
    rangeStartValue, rangeEndValue, deviceInstanceId, disabled,
  } = props;
  const dispatch = useDispatch();

  const [isAbsoluteValue, setIsAbsoluteValue] = useState(true);
  const convertedValue = isAbsoluteValue ? statusValue.value : convertToPercentage(statusValue.value, rangeStartValue, rangeEndValue);

  return (
    <div>
      <div>
        <ForceRadioControl
          value={isAbsoluteValue}
          disabled={disabled}
          onChange={() => setIsAbsoluteValue((prev) => !prev)}
        />
      </div>
      <div>
        <ForceAnalogEditor
          value={convertedValue}
          statusDescriptor={statusDescriptor}
          statusValue={statusValue}
          button={button}
          unit={isAbsoluteValue ? unit : '%'}
          deviceInstanceId={deviceInstanceId}
          disabled={disabled}
          actionId={actionId}
          onValueChange={(value) => {
            const valueToSend = isAbsoluteValue ? value : convertToAbsolute(value, rangeStartValue, rangeEndValue);
            dispatch(writeActiveDeviceVariableValues(deviceInstanceId, [{
              value: valueToSend,
              backupValue: statusValue.backupValue,
              identRef: statusValue.identRef,
            }]));
          }}
        />
      </div>
    </div>
  );
};
