/* eslint-disable max-len */
import { DeviceModelStatus } from '@gpt/commons';
import {
  ContactronModuleCollection, ContactronStationWizardState, ContactronWizardGroupMode,
} from '../../../wizards/contactronStation/types';
import { DatasetState } from '../../../deviceInstances/store/deviceDataset/types';

interface RowData {
    value: string;
}

interface Row {
    data: RowData[];
}

const headers = ['WIZARD_CONTACTRON__TABLE__HEADER_POSITION', 'WIZARD_CONTACTRON__TABLE__HEADER_DEVICE_TAG',
  'WIZARD_CONTACTRON__TABLE__HEADER_IFS_ADDRESS', 'WIZARD_CONTACTRON__TABLE__HEADER_PROCESS_VALUE_NAME', 'WIZARD_CONTACTRON__TABLE__HEADER_DEVICE_TYPE'];

const setReportTableRowData = (
  deviceDataCheckboxDesc: DeviceModelStatus.Wizards.ContactronStation.DeviceTagDataCollection,
  moduleCheckboxes: {[key: string]: boolean},
  tag: string,
  address: number,
  deviceType: string,
): Row[] => Object.keys(deviceDataCheckboxDesc).reduce((acc, key) => {
  const moduleCheckbox = deviceDataCheckboxDesc[key];
  const value = moduleCheckboxes[moduleCheckbox.name] ?? moduleCheckbox.checked;

  if (!value) {
    return acc;
  }

  const data = [
    { value: tag },
    { value: `${address}` },
    { value: deviceDataCheckboxDesc[key].label },
    { value: deviceType },
  ];

  return [...acc, { data: data }];
}, [] as Row[]);

export const setReportTableRows = (
  devices: DeviceModelStatus.Wizards.ContactronStation.StationDeviceCollectionType,
  deviceDataset: DatasetState,
  modules: ContactronModuleCollection,
  kind: 'input' | 'output',
  moduleType: 'station' | 'module',
): Row[] => Object.entries(modules).reduce((acc: Row[], [, module]) => {
  const device = devices[module.moduleId];
  if (!device) {
    return acc;
  }

  if (module.type !== moduleType) {
    return acc;
  }

  const deviceDataCheckboxDesc = kind === 'input' ? device.inputDataDescription : device.outputDataDescription;

  const moduleCheckboxes = kind === 'input' ? module.inputDataCheckbox : module.outputDataCheckbox;

  const deviceTagVarName = moduleType === 'module' ? `DEVICE${module.instance.address}_IFSM_ADDR_AKZ` : 'IFSM_ADDR_APPL_AKZ';
  const deviceTagValue = deviceDataset.values[deviceTagVarName]?.value ?? '';

  const row = setReportTableRowData(deviceDataCheckboxDesc, moduleCheckboxes, deviceTagValue, module.instance.address, module.instance.name);

  return [...acc, ...row];
}, []);

const sortByCheckboxName = (rows: Row[]): Row[] => rows.sort((a, b) => {
  const aValue = a.data[2].value; // Third value is checkbox name
  const bValue = b.data[2].value;
  return aValue.localeCompare(bValue);
});

export const getReportTableJson = (
  state: ContactronStationWizardState,
  deviceDataset: DatasetState,
  deviceList: DeviceModelStatus.Wizards.ContactronStation.StationDeviceCollectionType,
): string => {
  const inputStationRowsData = setReportTableRows(deviceList, deviceDataset, state.modules, 'input', 'station');
  const outputStationRowsData = setReportTableRows(deviceList, deviceDataset, state.modules, 'output', 'station');

  let inputRowsData = setReportTableRows(deviceList, deviceDataset, state.modules, 'input', 'module');
  let outputRowsData = setReportTableRows(deviceList, deviceDataset, state.modules, 'output', 'module');

  if (state.groupMode === ContactronWizardGroupMode.ProcessValueName) {
    inputRowsData = sortByCheckboxName(inputRowsData);
    outputRowsData = sortByCheckboxName(outputRowsData);
  }

  const inputRows: Row[] = [...inputStationRowsData, ...inputRowsData];
  inputRows.forEach((row, index) => {
    row.data.unshift({ value: index.toString() });
  });

  const outputRows: Row[] = [...outputStationRowsData, ...outputRowsData];
  outputRows.forEach((row, index) => {
    row.data.unshift({ value: index.toString() });
  });

  const reportTable = {
    headers: headers,
    body: {
      inputRowsTitle: 'WIZARD_CONTACTRON__PAGE6_INPUT_PROCESS_DATA__TITLE',
      outputRowsTitle: 'WIZARD_CONTACTRON__PAGE6_OUTPUT_PROCESS_DATA__TITLE',
      inputRows: inputRows,
      outputRows: outputRows,
    },
  };

  return JSON.stringify(reportTable);
};
