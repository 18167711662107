/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import { useTranslation } from 'react-i18next';
import './StationProcessData.scss';
import { ProcessDataInputOutput } from '../ProcessDataInputOutput/ProcessDataInputOutput';
import { useTypedSelector } from '../../../../../../store';
import { ProgressBar } from '../../../../../../components/ProgressBar/ProgressBar';

const convertToPercentage = (selectedCount: number): number => (selectedCount / 16) * 100;

export interface StationProcessDataProps {
  moduleDesc: DeviceModelStatus.Wizards.ContactronStation.StationDeviceType;
}

export const StationProcessData: React.FC<StationProcessDataProps> = (props: StationProcessDataProps):
React.ReactElement => {
  const { t } = useTranslation();
  const { moduleDesc } = props;
  const totalModuleSelected = useTypedSelector((state) => state.contactronInitWizard.modules[state.contactronInitWizard.selectedModule].totalSelected);
  const percent = convertToPercentage(totalModuleSelected);

  return (
    <div className="station-process-data-container">
      <div className="station-process-data-container__header">
        <div>
          <strong>{t('WIZARD_CONTACTRON__PAGE5_SELECT_STATION_DATA')}</strong>
          {' '}
          (max. 16)
        </div>
      </div>
      <div className="station-process-data-container__device_progress">
        {t('WIZARD_CONTACTRON__PAGE5_STATION_PROCESS_DATA')}
        {' '}
        {totalModuleSelected}
        {' '}
        / 16
        <div className="station-process-data-container__device_progress-bar">
          <ProgressBar percent={percent} />
        </div>
      </div>
      <div className="station-process-data-container__select">
        <ProcessDataInputOutput moduleDescription={moduleDesc} />
      </div>
      <div className="station-process-data-container__apply-for-all-devices" />
    </div>
  );
};
