/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */

import React from 'react';
import { DeviceModelStatus } from '@gpt/commons';
import { ProgressBar } from '../../../../../components/ProgressBar/ProgressBar';
import { StationProcessData } from './StationProcessData/StationProcessData';
import { DeviceProcessData } from './DeviceProcessData/DeviceProcessData';
import SelectDevice from './SelectDevice/SelectDevice';
import { useTypedSelector } from '../../../../../store';
import './SelectProcessDataParent.scss';

const convertToPercentage = (selectedCount) => (selectedCount / 64) * 100;

export interface SelectProcessDataParentProps {
  stationDeviceDescription: DeviceModelStatus.Wizards.ContactronStation.StationDeviceCollectionType;
}

export const SelectProcessDataParent:
React.FC<SelectProcessDataParentProps> = (props: SelectProcessDataParentProps):
React.ReactElement => {
  const { stationDeviceDescription } = props;
  const totalSelected = useTypedSelector((state) => state.contactronInitWizard.totalSelected);

  const module = useTypedSelector((state) => state.contactronInitWizard.modules[state.contactronInitWizard.selectedModule]);
  // const moduleId = useTypedSelector((state) => state.contactronInitWizard.modules[state.contactronInitWizard.selectedModule].moduleId);
  const moduleDesc = stationDeviceDescription[module.moduleId];
  const percent = convertToPercentage(totalSelected);

  const processData = module.type === 'station'
    ? <StationProcessData moduleDesc={moduleDesc} />
    : <DeviceProcessData moduleDesc={moduleDesc} />;

  return (
    <div className="selectprocessdataparent-container">
      <div className="selectprocessdataparent-device-selection">
        <SelectDevice stationDeviceDescription={stationDeviceDescription} />
      </div>
      <div className="selectprocessdataparent-process-data-selection">
        {processData}
      </div>
      <div className="selectprocessdataparent-progressbar">
        <ProgressBar percent={percent} />
        <div className="selectprocessdataparent-progressbar-text">{`Prozessdaten Gesamt ${totalSelected} / 64`}</div>
      </div>
    </div>
  );
};
