/* eslint-disable max-len */
/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import { IdentRef, DeviceModelStatus } from '@gpt/commons';
import React from 'react';
import { ReduxControlProps } from '../../controls/ReduxControlProps';
import { useTypedSelector } from '../../store';
import { deviceDescriptorSelector } from '../../store/deviceInstances/store/deviceDataset/selector';
import { DeviceInstancesState } from '../../store/deviceInstances/types';
import { DatasetType } from '../../store/deviceInstances/store/deviceDataset/types';
import withControlVisibility from '../../controls/hoc/withControlVisibility';
import CommandBlock from './CommandBlock/CommandBlock';
import ForceOutputBlock from './ForceOutputBlock/ForceOutputBlock';
import { ImageComponent } from '../ImageComponent';
import './SPortSpecificControl.scss';
import ColdJunctionLineCompensation from './ColdJunctionLineCompensation/ColdJunctionLineCompensation';

const sportSpecificControlSelector = (
  state: DeviceInstancesState,
  deviceInstanceId: string | undefined,
  targetDataset: DatasetType,
  identRef: IdentRef,
): DeviceModelStatus.UI.SPort.SPortSpecificControlType | undefined => {
  const descriptor = deviceDescriptorSelector(state, deviceInstanceId, targetDataset, identRef);
  if (descriptor?.type !== DeviceModelStatus.StatusType.ControlDescriptor) {
    return undefined;
  }
  const { controlType } = descriptor;
  return controlType?.type !== DeviceModelStatus.UI.ControlType.CTLSPORTSPECIFIC
    ? undefined
    : controlType;
};

const SPortSpecificControl: React.FC<ReduxControlProps> = (props: ReduxControlProps):
  React.ReactElement => {
  const {
    identRef,
    deviceInstanceId,
  } = props;

  const sportSpecific = useTypedSelector((state) => sportSpecificControlSelector(state.deviceInstances, deviceInstanceId, DatasetType.device, identRef));

  if (sportSpecific?.blockControl.type === DeviceModelStatus.UI.SPort.SPortSpecificType.CTLCOMMANDBLOCK) {
    return (
      <div className="sport-specific">
        <div className="sport-specific-block">
          <CommandBlock block={sportSpecific.blockControl} deviceInstanceId={deviceInstanceId} />
        </div>
        <div className="sport-specific-image">
          <ImageComponent imageResourceId={sportSpecific?.blockControl.picture} deviceInstanceId={deviceInstanceId} />
        </div>
      </div>
    );
  }

  if (sportSpecific?.blockControl.type === DeviceModelStatus.UI.SPort.SPortSpecificType.CTLFORCEOUTPUTBLOCK) {
    return (
      <div className="sport-specific">
        <div className="sport-specific-block">
          <ForceOutputBlock block={sportSpecific.blockControl} deviceInstanceId={deviceInstanceId} />
        </div>
        <div className="sport-specific-image">
          <ImageComponent imageResourceId={sportSpecific?.blockControl.picture} deviceInstanceId={deviceInstanceId} />
        </div>
      </div>
    );
  }

  if (sportSpecific?.blockControl.type === DeviceModelStatus.UI.SPort.SPortSpecificType.CTLCOLDJUNCTIONLINECOMPENSATION) {
    return (
      <div className="w-100">
        <ColdJunctionLineCompensation
          title={sportSpecific.blockControl.title}
          block={sportSpecific.blockControl.block}
          deviceInstanceId={deviceInstanceId}
        />
      </div>
    );
  }

  return <div>Unknown</div>;
};

export default withControlVisibility(SPortSpecificControl);
