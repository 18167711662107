/* ****************************************************************************
 *
 * Copyright PHOENIX CONTACT
 *
 * Project: clipx ENGINEER devicetool
 * Component: User Interface (Web Application)
 *
 **************************************************************************** */
import i18nEn from './assets/i18n/translation_en.json';
import i18nDe from './assets/i18n/translation_de.json';
import i18nEs from './assets/i18n/translation_es.json';
import i18nFr from './assets/i18n/translation_fr.json';
import i18nIt from './assets/i18n/translation_it.json';
import i18nJa from './assets/i18n/translation_ja.json';
import i18nPl from './assets/i18n/translation_pl.json';
import i18nPt from './assets/i18n/translation_pt.json';
import i18nRu from './assets/i18n/translation_ru.json';
import i18nTr from './assets/i18n/translation_tr.json';
import i18nZh from './assets/i18n/translation_zh.json';
import i18nCs from './assets/i18n/translation_cs.json';
import i18nDa from './assets/i18n/translation_da.json';
import i18nFi from './assets/i18n/translation_fi.json';
import i18nHu from './assets/i18n/translation_hu.json';
import i18nKo from './assets/i18n/translation_ko.json';
import i18nNb from './assets/i18n/translation_nb.json';
import i18nNl from './assets/i18n/translation_nl.json';
import i18nRo from './assets/i18n/translation_ro.json';
import i18nSv from './assets/i18n/translation_sv.json';
import i18nUk from './assets/i18n/translation_uk.json';

import i18nMINIMCREn from './assets/i18n/Devices/MINI_MCR/MINI_MCR_en.json';
import i18nMINIMCRDe from './assets/i18n/Devices/MINI_MCR/MINI_MCR_de.json';
import i18nMINIMCREs from './assets/i18n/Devices/MINI_MCR/MINI_MCR_es.json';
import i18nMINIMCRFr from './assets/i18n/Devices/MINI_MCR/MINI_MCR_fr.json';
import i18nMINIMCRIt from './assets/i18n/Devices/MINI_MCR/MINI_MCR_it.json';
import i18nMINIMCRJa from './assets/i18n/Devices/MINI_MCR/MINI_MCR_ja.json';
import i18nMINIMCRPl from './assets/i18n/Devices/MINI_MCR/MINI_MCR_pl.json';
import i18nMINIMCRPt from './assets/i18n/Devices/MINI_MCR/MINI_MCR_pt.json';
import i18nMINIMCRRu from './assets/i18n/Devices/MINI_MCR/MINI_MCR_ru.json';
import i18nMINIMCRTr from './assets/i18n/Devices/MINI_MCR/MINI_MCR_tr.json';
import i18nMINIMCRZh from './assets/i18n/Devices/MINI_MCR/MINI_MCR_zh.json';
import i18nMINIMCRCs from './assets/i18n/Devices/MINI_MCR/MINI_MCR_cs.json';
import i18nMINIMCRDa from './assets/i18n/Devices/MINI_MCR/MINI_MCR_da.json';
import i18nMINIMCRFi from './assets/i18n/Devices/MINI_MCR/MINI_MCR_fi.json';
import i18nMINIMCRHu from './assets/i18n/Devices/MINI_MCR/MINI_MCR_hu.json';
import i18nMINIMCRKo from './assets/i18n/Devices/MINI_MCR/MINI_MCR_ko.json';
import i18nMINIMCRNb from './assets/i18n/Devices/MINI_MCR/MINI_MCR_nb.json';
import i18nMINIMCRNl from './assets/i18n/Devices/MINI_MCR/MINI_MCR_nl.json';
import i18nMINIMCRRo from './assets/i18n/Devices/MINI_MCR/MINI_MCR_ro.json';
import i18nMINIMCRSv from './assets/i18n/Devices/MINI_MCR/MINI_MCR_sv.json';
import i18nMINIMCRUk from './assets/i18n/Devices/MINI_MCR/MINI_MCR_uk.json';

import i18nQUINT4PSEn from './assets/i18n/Devices/QUINT4_PS/QUINT4_PS_en.json';
import i18nQUINT4PSDe from './assets/i18n/Devices/QUINT4_PS/QUINT4_PS_de.json';
import i18nQUINT4PSEs from './assets/i18n/Devices/QUINT4_PS/QUINT4_PS_es.json';
import i18nQUINT4PSFr from './assets/i18n/Devices/QUINT4_PS/QUINT4_PS_fr.json';
import i18nQUINT4PSIt from './assets/i18n/Devices/QUINT4_PS/QUINT4_PS_it.json';
import i18nQUINT4PSJa from './assets/i18n/Devices/QUINT4_PS/QUINT4_PS_ja.json';
import i18nQUINT4PSPl from './assets/i18n/Devices/QUINT4_PS/QUINT4_PS_pl.json';
import i18nQUINT4PSPt from './assets/i18n/Devices/QUINT4_PS/QUINT4_PS_pt.json';
import i18nQUINT4PSRu from './assets/i18n/Devices/QUINT4_PS/QUINT4_PS_ru.json';
import i18nQUINT4PSTr from './assets/i18n/Devices/QUINT4_PS/QUINT4_PS_tr.json';
import i18nQUINT4PSZh from './assets/i18n/Devices/QUINT4_PS/QUINT4_PS_zh.json';
import i18nQUINT4PSCs from './assets/i18n/Devices/QUINT4_PS/QUINT4_PS_cs.json';
import i18nQUINT4PSDa from './assets/i18n/Devices/QUINT4_PS/QUINT4_PS_da.json';
import i18nQUINT4PSFi from './assets/i18n/Devices/QUINT4_PS/QUINT4_PS_fi.json';
import i18nQUINT4PSHu from './assets/i18n/Devices/QUINT4_PS/QUINT4_PS_hu.json';
import i18nQUINT4PSKo from './assets/i18n/Devices/QUINT4_PS/QUINT4_PS_ko.json';
import i18nQUINT4PSNb from './assets/i18n/Devices/QUINT4_PS/QUINT4_PS_nb.json';
import i18nQUINT4PSNl from './assets/i18n/Devices/QUINT4_PS/QUINT4_PS_nl.json';
import i18nQUINT4PSRo from './assets/i18n/Devices/QUINT4_PS/QUINT4_PS_ro.json';
import i18nQUINT4PSSv from './assets/i18n/Devices/QUINT4_PS/QUINT4_PS_sv.json';
import i18nQUINT4PSUk from './assets/i18n/Devices/QUINT4_PS/QUINT4_PS_uk.json';

import i18nTRIO3PSEn from './assets/i18n/Devices/TRIO3_PS/TRIO3_PS_en.json';
import i18nTRIO3PSDe from './assets/i18n/Devices/TRIO3_PS/TRIO3_PS_de.json';
import i18nTRIO3PSEs from './assets/i18n/Devices/TRIO3_PS/TRIO3_PS_es.json';
import i18nTRIO3PSFr from './assets/i18n/Devices/TRIO3_PS/TRIO3_PS_fr.json';
import i18nTRIO3PSIt from './assets/i18n/Devices/TRIO3_PS/TRIO3_PS_it.json';
import i18nTRIO3PSJa from './assets/i18n/Devices/TRIO3_PS/TRIO3_PS_ja.json';
import i18nTRIO3PSPl from './assets/i18n/Devices/TRIO3_PS/TRIO3_PS_pl.json';
import i18nTRIO3PSPt from './assets/i18n/Devices/TRIO3_PS/TRIO3_PS_pt.json';
import i18nTRIO3PSRu from './assets/i18n/Devices/TRIO3_PS/TRIO3_PS_ru.json';
import i18nTRIO3PSTr from './assets/i18n/Devices/TRIO3_PS/TRIO3_PS_tr.json';
import i18nTRIO3PSZh from './assets/i18n/Devices/TRIO3_PS/TRIO3_PS_zh.json';
import i18nTRIO3PSCs from './assets/i18n/Devices/TRIO3_PS/TRIO3_PS_cs.json';
import i18nTRIO3PSDa from './assets/i18n/Devices/TRIO3_PS/TRIO3_PS_da.json';
import i18nTRIO3PSFi from './assets/i18n/Devices/TRIO3_PS/TRIO3_PS_fi.json';
import i18nTRIO3PSHu from './assets/i18n/Devices/TRIO3_PS/TRIO3_PS_hu.json';
import i18nTRIO3PSKo from './assets/i18n/Devices/TRIO3_PS/TRIO3_PS_ko.json';
import i18nTRIO3PSNb from './assets/i18n/Devices/TRIO3_PS/TRIO3_PS_nb.json';
import i18nTRIO3PSNl from './assets/i18n/Devices/TRIO3_PS/TRIO3_PS_nl.json';
import i18nTRIO3PSRo from './assets/i18n/Devices/TRIO3_PS/TRIO3_PS_ro.json';
import i18nTRIO3PSSv from './assets/i18n/Devices/TRIO3_PS/TRIO3_PS_sv.json';
import i18nTRIO3PSUk from './assets/i18n/Devices/TRIO3_PS/TRIO3_PS_uk.json';

import i18nCAPAROCen from './assets/i18n/Devices/CAPAROC/CAPAROC_en.json';
import i18nCAPAROCde from './assets/i18n/Devices/CAPAROC/CAPAROC_de.json';
import i18nCAPAROCEs from './assets/i18n/Devices/CAPAROC/CAPAROC_es.json';
import i18nCAPAROCFr from './assets/i18n/Devices/CAPAROC/CAPAROC_fr.json';
import i18nCAPAROCIt from './assets/i18n/Devices/CAPAROC/CAPAROC_it.json';
import i18nCAPAROCJa from './assets/i18n/Devices/CAPAROC/CAPAROC_ja.json';
import i18nCAPAROCPl from './assets/i18n/Devices/CAPAROC/CAPAROC_pl.json';
import i18nCAPAROCPt from './assets/i18n/Devices/CAPAROC/CAPAROC_pt.json';
import i18nCAPAROCRu from './assets/i18n/Devices/CAPAROC/CAPAROC_ru.json';
import i18nCAPAROCTr from './assets/i18n/Devices/CAPAROC/CAPAROC_tr.json';
import i18nCAPAROCZh from './assets/i18n/Devices/CAPAROC/CAPAROC_zh.json';
import i18nCAPAROCCs from './assets/i18n/Devices/CAPAROC/CAPAROC_cs.json';
import i18nCAPAROCDa from './assets/i18n/Devices/CAPAROC/CAPAROC_da.json';
import i18nCAPAROCFi from './assets/i18n/Devices/CAPAROC/CAPAROC_fi.json';
import i18nCAPAROCHu from './assets/i18n/Devices/CAPAROC/CAPAROC_hu.json';
import i18nCAPAROCKo from './assets/i18n/Devices/CAPAROC/CAPAROC_ko.json';
import i18nCAPAROCNb from './assets/i18n/Devices/CAPAROC/CAPAROC_nb.json';
import i18nCAPAROCNl from './assets/i18n/Devices/CAPAROC/CAPAROC_nl.json';
import i18nCAPAROCRo from './assets/i18n/Devices/CAPAROC/CAPAROC_ro.json';
import i18nCAPAROCSv from './assets/i18n/Devices/CAPAROC/CAPAROC_sv.json';
import i18nCAPAROCUk from './assets/i18n/Devices/CAPAROC/CAPAROC_uk.json';

import i18nCONTACTRONen from './assets/i18n/Devices/CONTACTRON/CONTACTRON_en.json';
import i18nCONTACTRONde from './assets/i18n/Devices/CONTACTRON/CONTACTRON_de.json';
import i18nCONTACTRONEs from './assets/i18n/Devices/CONTACTRON/CONTACTRON_es.json';
import i18nCONTACTRONFr from './assets/i18n/Devices/CONTACTRON/CONTACTRON_fr.json';
import i18nCONTACTRONIt from './assets/i18n/Devices/CONTACTRON/CONTACTRON_it.json';
import i18nCONTACTRONJa from './assets/i18n/Devices/CONTACTRON/CONTACTRON_ja.json';
import i18nCONTACTRONPl from './assets/i18n/Devices/CONTACTRON/CONTACTRON_pl.json';
import i18nCONTACTRONPt from './assets/i18n/Devices/CONTACTRON/CONTACTRON_pt.json';
import i18nCONTACTRONRu from './assets/i18n/Devices/CONTACTRON/CONTACTRON_ru.json';
import i18nCONTACTRONTr from './assets/i18n/Devices/CONTACTRON/CONTACTRON_tr.json';
import i18nCONTACTRONZh from './assets/i18n/Devices/CONTACTRON/CONTACTRON_zh.json';
import i18nCONTACTRONCs from './assets/i18n/Devices/CONTACTRON/CONTACTRON_cs.json';
import i18nCONTACTRONDa from './assets/i18n/Devices/CONTACTRON/CONTACTRON_da.json';
import i18nCONTACTRONFi from './assets/i18n/Devices/CONTACTRON/CONTACTRON_fi.json';
import i18nCONTACTRONHu from './assets/i18n/Devices/CONTACTRON/CONTACTRON_hu.json';
import i18nCONTACTRONKo from './assets/i18n/Devices/CONTACTRON/CONTACTRON_ko.json';
import i18nCONTACTRONNb from './assets/i18n/Devices/CONTACTRON/CONTACTRON_nb.json';
import i18nCONTACTRONNl from './assets/i18n/Devices/CONTACTRON/CONTACTRON_nl.json';
import i18nCONTACTRONRo from './assets/i18n/Devices/CONTACTRON/CONTACTRON_ro.json';
import i18nCONTACTRONSv from './assets/i18n/Devices/CONTACTRON/CONTACTRON_sv.json';
import i18nCONTACTRONUk from './assets/i18n/Devices/CONTACTRON/CONTACTRON_uk.json';

import i18nECMUSIen from './assets/i18n/Devices/ECM_UC/ECM_UC_en.json';
import i18nECMUSIde from './assets/i18n/Devices/ECM_UC/ECM_UC_de.json';

/**
 * Default translation for application
 */
const bundledResources = {
  en: {
    translation: i18nEn,
    MINI_MCR: i18nMINIMCREn,
    QUINT4_PS: i18nQUINT4PSEn,
    TRIO3_PS: i18nTRIO3PSEn,
    CAPAROC: i18nCAPAROCen,
    CONTACTRON: i18nCONTACTRONen,
    ECM_UC: i18nECMUSIen,
  },
  de: {
    translation: i18nDe,
    MINI_MCR: i18nMINIMCRDe,
    QUINT4_PS: i18nQUINT4PSDe,
    TRIO3_PS: i18nTRIO3PSDe,
    CAPAROC: i18nCAPAROCde,
    CONTACTRON: i18nCONTACTRONde,
    ECM_UC: i18nECMUSIde,
  },
  es: {
    translation: i18nEs,
    MINI_MCR: i18nMINIMCREs,
    QUINT4_PS: i18nQUINT4PSEs,
    TRIO3_PS: i18nTRIO3PSEs,
    CAPAROC: i18nCAPAROCEs,
    CONTACTRON: i18nCONTACTRONEs,
  },
  fr: {
    translation: i18nFr,
    MINI_MCR: i18nMINIMCRFr,
    QUINT4_PS: i18nQUINT4PSFr,
    TRIO3_PS: i18nTRIO3PSFr,
    CAPAROC: i18nCAPAROCFr,
    CONTACTRON: i18nCONTACTRONFr,
  },
  it: {
    translation: i18nIt,
    MINI_MCR: i18nMINIMCRIt,
    QUINT4_PS: i18nQUINT4PSIt,
    TRIO3_PS: i18nTRIO3PSIt,
    CAPAROC: i18nCAPAROCIt,
    CONTACTRON: i18nCONTACTRONIt,
  },
  ja: {
    translation: i18nJa,
    MINI_MCR: i18nMINIMCRJa,
    QUINT4_PS: i18nQUINT4PSJa,
    TRIO3_PS: i18nTRIO3PSJa,
    CAPAROC: i18nCAPAROCJa,
    CONTACTRON: i18nCONTACTRONJa,
  },
  pl: {
    translation: i18nPl,
    MINI_MCR: i18nMINIMCRPl,
    QUINT4_PS: i18nQUINT4PSPl,
    TRIO3_PS: i18nTRIO3PSPl,
    CAPAROC: i18nCAPAROCPl,
    CONTACTRON: i18nCONTACTRONPl,
  },
  pt: {
    translation: i18nPt,
    MINI_MCR: i18nMINIMCRPt,
    QUINT4_PS: i18nQUINT4PSPt,
    TRIO3_PS: i18nTRIO3PSPt,
    CAPAROC: i18nCAPAROCPt,
    CONTACTRON: i18nCONTACTRONPt,
  },
  ru: {
    translation: i18nRu,
    MINI_MCR: i18nMINIMCRRu,
    QUINT4_PS: i18nQUINT4PSRu,
    TRIO3_PS: i18nTRIO3PSRu,
    CAPAROC: i18nCAPAROCRu,
    CONTACTRON: i18nCONTACTRONRu,
  },
  tr: {
    translation: i18nTr,
    MINI_MCR: i18nMINIMCRTr,
    QUINT4_PS: i18nQUINT4PSTr,
    TRIO3_PS: i18nTRIO3PSTr,
    CAPAROC: i18nCAPAROCTr,
    CONTACTRON: i18nCONTACTRONTr,
  },
  zh: {
    translation: i18nZh,
    MINI_MCR: i18nMINIMCRZh,
    QUINT4_PS: i18nQUINT4PSZh,
    TRIO3_PS: i18nTRIO3PSZh,
    CAPAROC: i18nCAPAROCZh,
    CONTACTRON: i18nCONTACTRONZh,
  },
  cs: {
    translation: i18nCs,
    MINI_MCR: i18nMINIMCRCs,
    QUINT4_PS: i18nQUINT4PSCs,
    TRIO3_PS: i18nTRIO3PSCs,
    CAPAROC: i18nCAPAROCCs,
    CONTACTRON: i18nCONTACTRONCs,
  },
  da: {
    translation: i18nDa,
    MINI_MCR: i18nMINIMCRDa,
    QUINT4_PS: i18nQUINT4PSDa,
    TRIO3_PS: i18nTRIO3PSDa,
    CAPAROC: i18nCAPAROCDa,
    CONTACTRON: i18nCONTACTRONDa,
  },
  fi: {
    translation: i18nFi,
    MINI_MCR: i18nMINIMCRFi,
    QUINT4_PS: i18nQUINT4PSFi,
    TRIO3_PS: i18nTRIO3PSFi,
    CAPAROC: i18nCAPAROCFi,
    CONTACTRON: i18nCONTACTRONFi,
  },
  hu: {
    translation: i18nHu,
    MINI_MCR: i18nMINIMCRHu,
    QUINT4_PS: i18nQUINT4PSHu,
    TRIO3_PS: i18nTRIO3PSHu,
    CAPAROC: i18nCAPAROCHu,
    CONTACTRON: i18nCONTACTRONHu,
  },
  ko: {
    translation: i18nKo,
    MINI_MCR: i18nMINIMCRKo,
    QUINT4_PS: i18nQUINT4PSKo,
    TRIO3_PS: i18nTRIO3PSKo,
    CAPAROC: i18nCAPAROCKo,
    CONTACTRON: i18nCONTACTRONKo,
  },
  nb: {
    translation: i18nNb,
    MINI_MCR: i18nMINIMCRNb,
    QUINT4_PS: i18nQUINT4PSNb,
    TRIO3_PS: i18nTRIO3PSNb,
    CAPAROC: i18nCAPAROCNb,
    CONTACTRON: i18nCONTACTRONNb,
  },
  nl: {
    translation: i18nNl,
    MINI_MCR: i18nMINIMCRNl,
    QUINT4_PS: i18nQUINT4PSNl,
    TRIO3_PS: i18nTRIO3PSNl,
    CAPAROC: i18nCAPAROCNl,
    CONTACTRON: i18nCONTACTRONNl,
  },
  ro: {
    translation: i18nRo,
    MINI_MCR: i18nMINIMCRRo,
    QUINT4_PS: i18nQUINT4PSRo,
    TRIO3_PS: i18nTRIO3PSRo,
    CAPAROC: i18nCAPAROCRo,
    CONTACTRON: i18nCONTACTRONRo,
  },
  sv: {
    translation: i18nSv,
    MINI_MCR: i18nMINIMCRSv,
    QUINT4_PS: i18nQUINT4PSSv,
    TRIO3_PS: i18nTRIO3PSSv,
    CAPAROC: i18nCAPAROCSv,
    CONTACTRON: i18nCONTACTRONSv,
  },
  uk: {
    translation: i18nUk,
    MINI_MCR: i18nMINIMCRUk,
    QUINT4_PS: i18nQUINT4PSUk,
    TRIO3_PS: i18nTRIO3PSUk,
    CAPAROC: i18nCAPAROCUk,
    CONTACTRON: i18nCONTACTRONUk,
  },
};

export default bundledResources;
